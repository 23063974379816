/* eslint-disable max-len */
import {sharedTranslations} from '../../libs/translations/en';

export class English {
  static translations = {
    sharedTranslations,
    popups: {
      deactivateUser: {
        title: 'Confirm deactivation',
        text:
          'Are you sure you want to deactivate your profile? This means all your data will be deleted. In order to use IAMconnected, you must create an account again.',
      },
      backToMainMenuNewOrg: {
        title: 'Back to the main menu?',
        text:
          "Do you want to go back to the main menu (the company's data will be retained) or do you want to search for another company?",
        confirm: 'Go back to the main menu',
        cancel: 'Search another company',
      },
    },
    buttons: {
      send: 'Send',
      delete: 'Delete',
      reset: 'Reset',
      cancel: 'Cancel',
      cancelRequest: 'Cancel request',
      ok: 'Ok',
      submit: 'Submit',
      update: 'Update',
      change: 'Change',
      activate: 'Activate',
      deactivate: 'Deactivate',
      save: 'Save',
      create: 'Create',
      register: 'Create an account',
      login: 'Log in',
      logout: 'Log out',
      close: 'Close',
      back: 'Back',
      next: 'Next',
      postponeRequest: 'Finish later',
      toHome: 'To the home page',
      toLogin: 'To the login-in page',
      resetToken: 'Reset token',
      editPersonalInformation: 'Edit personal information',
      changePassword: 'Change password',
      signUpWithOrganization: 'Sign up with organization',
      coupleWithOrganization: 'Couple me to this organization',
      homeLogin: 'Log in directly to IAMconnected',
      homeRegister: 'Create your own IAMconnected account now',
      addOrganization: 'Register new organization',
      accept: 'Accept',
      deny: 'Deny',
      expandAll: 'Expand all',
      collapseAll: 'Collapse all',
      search: 'Search',
      refreshData: 'Refresh data',
      changeRequest: 'Request change',
      validateData: 'Validate data',
      requestNewOrganization: 'Request new organization',
      identifyAgain: 'Identify again',
      resendIdentify: 'Resend invitation',
      clickHere: 'Click here',
      yes: 'Yes',
      noEdit: 'No, change data',
      cancelOpenRequest: 'Cancel open request',
      detailOpenRequest: 'Open Request Details',
      confirm: 'Confirm'
    },
    tooltips: {
      validated: 'Validated',
      notValidated: 'Not validated in the last 3 months',
      validationNotRequired: 'Validation not required',
      validationByAdmin: 'Validate by main administrator'
    },
    icons: {
      simple: 'simple',
      safe: 'secure',
      personal: 'personal',
      free: 'free',
    },
    warningBox: {
      openRequestWarning: {
        content:
          'It’s not possible to do a new request, because you already have an open request.',
        button: 'Cancel open request',
      },
    },
    errorTexts: {
      errorSnackbar: {
        title: 'An unexpected error has occurred.',
        mention: 'Click here to report this to',
        error: 'Errorcode: '
      },
      authorization: {
        nonUniqueBusinessEmail: 'This e-mail address already exists',
        missingBusinessInformation: 'Business information is missing',
        missingBusinessIdentification:
          'Personal organisation identification is missing',
        missingBusinessPhone: 'Business phone number is missing',
        missingBusinessEmail: 'Business email-address is missing',
      },
      general: {
        title: 'Error',
        badRequest:
          'Unfortunately, something went wrong. Check the entered data and try again.',
        forbidden: 'You are not authorised',
        unknown: `Unfortunately, something went wrong. Please try again.`,
        internalServerError: `Unfortunately, something went wrong. Please try again.`,
        missingInformation: 'The following information is still missing',
        httpStatusCodes: {
          '0': 'Service not reachable',
          '401':
            'You are currently not or no longer signed in. You will be directed to the log-in page.',
          '403': 'You are not authorised to use this functionality.',
          '404': 'No resource has been found.',
          '500': 'Something went wrong',
          '503': 'Service unavailable',
        },
        required: 'Please fill in a value',
        validationFailed: 'The activation code is not correct',
      },
      user: {
        operationNotAllowed: 'This action is not allowed',
        userNameAlreadyExists: 'Username already exists',
        notAllowedToConnectToThisOrganization:
          'Only Portbase employees can link themselves to this organization',
        updateCredentialsFailed:
          'The new password cannot be the same as the current password or the current password has been entered incorrectly. Please try again.',
        invalidUsername: 'The entered username is not valid',
        usernameIsEmailAddress:
          'The entered username cannot be an email address',
        emailNotVerified: 'Email address has not yet been verified',
      },
      notAccepted: 'Please accept the terms before continuing',
      notAlphaNumeric: 'Value is not alphanumeric',
      notNumeric: 'Only numeric values are allowed',
      notEmail: 'Incorrect email address',
      notPhone: 'Value is not a correct phone number',
      notOldPassword: 'Value is not a correct old password',
      notPassword: 'Value is not a correct password',
      notMatchPassword: 'Passwords do not match',
      notMaxLength:
        'Value must contain {{ requiredLength }} characters at the most',
      notMinLength:
        'Value must contain at least {{ requiredLength }} characters',
      noDataToDisplay: 'No data found to display',
      noFilterResultsFound:
        'No results found that match your criteria - please adust filter',
      noMainOrganization:
        'Linking to this organization is currently not possible',
      notFoundPage: {
        header: 'Not found',
        body:
          'The requested page could not be found. If you believe this is an error, please notify your Main Administrator.',
      },
      requiredFields: 'Fields marked with * are mandatory',
      cookiesDisabled: {
        title: 'Cookies are disabled',
        body:
          'This website needs functional cookies to work. Since cookies seem to have been disabled in your browser, you will not be able to use IAMconnected.',
      },
      organization: {
        cocIdAlreadyExists:
          'An organization with this CoC ID / Duns number already exists',
        operationNotAllowed: 'This action is not allowed',
        alreadyExists: 'Organization already exists',
        unknownResource: 'Data not found, please check your input.',
        invalidResourceSelected: 'The organization details cannot be found, the details cannot be updated. <br>Please contact Portbase Sales via +31 (0)88 625 25 34 to continue.',
      },
      register: '',
      organizationType: {
        invalidType: 'Inactive organization type selected',
      },
      changeRequest: {
        unknownResource: 'Data not found, please check your input.',
        invalidResourceSelected: 'Data not found, please check your input.',
        alreadyExists: 'Change request already exists',
        activeChangeRequest: 'Your contact details are currently being changed. You need these to register an organization.',
        activeNewOrgRequest: 'A registration is already underway.',
        invalidAction:
          'An unexpected error has occurred.',
        invalidRepresentative:
          'The change can only be activated by the new main administrator after approval of the authorized representative',
        activateNotAllowed:
          'This activation code can only be entered by the new main administrator',
      },
      emailNotVerified:
        'First validate your email address, go to profile and click on Validate email address.',
      validateByAdmin: 'Validation by main administrator',
      fileUpload: {
        fileSize: {
          title: 'file is too large',
          content: 'The maximum size allowed is 50MB.',
          tooltip: 'For instance an extract from the Chamber of Commerce or Business Register. <br> Allowed file types are pdf, jpg and jpeg.'

        },
        scan: {
          title: 'issue found during the scanning of this file.',
          content: 'Please try a different file. If the problem persists, please contact our Sales department via <u>sales@portbase.com</u>'
        },
        general: {
          title: 'issue found during uploading.',
          content: 'Please try a different file. If the problem persists, please contact our Sales department via <u>sales@portbase.com</u>'
        }
      },
      bouncedEmail: {
        hardBounce: '{{email}} is permanently unreachable. <br>Please update the email address, or contact your own organization\'s email provider or help desk to resolve this issue. Contact <a href="{{supportUrl}}" target="_blank">Customer Service</a> to have {{email}} listed as restored. This is necessary to receive emails again.',
        softBounce: '{{email}} is currently unavailable <br>Please check the email address. If necessary, contact your own organization\'s email provider or help desk if this problem persists.',
        mailboxFull: 'The inbox of {{email}}, is too full to receive emails. Please ask the authorized representative to empty this inbox to receive emails again.',
        authorizedRepr: {
          hardBounce: 'The specified authorized representative is permanently unavailable at {{email}}. <br>Cancel this request, and make a new request with a different email address for the authorized representative.',
          softBounce: 'The specified authorized representative cannot be reached at {{email}}. <br>Please resolve this through your own organization\'s email provider or help desk. Or cancel this request, and make a new request with different contact details.',
          mailboxFull: 'The inbox of {{email}}, is too full to receive emails. Please ask the authorized representative to empty this inbox to receive emails again.',
        }
      }
    },
    successTexts: {
      general: {
        title: 'Success!',
        cancelActivationCode: 'The request has been deleted'
      },
      couple:
        'Success! You will receive an e-mail when you have been accepted by the main administrator.',
      register: 'Success! You will receive an e-mail to activate your account',
      identification:
        'Success! You will receive an email to re-identify yourself',
      updateUser: 'Your profile has been successfully updated.',
      coupleOrganization:
        'As soon as your Main Administrator has allocated the appropriate rights, you will see the services that you have access to on the start page of IAMconnected.',
      createOrganization:
        'You have successfully registered your organization for IAMconnected. The authorized representative will be invited by email to identify him- or herself. After approval you will be able to finalize the registration. ',
      updateOrganization: 'Succeeded!',
      changeRequestSuccess:
        'Your change has been requested. You will shortly receive an activation code to activate your open request.',
      submitActivationCode: 'The organization is now active',
      cancelActivationCode: 'The request has been deleted',
      changeRequest: 'Success! request has been submitted.',
      resendIdentification: 'Success! An email has been sent to the authorized representative: ',
      verifyEmail: 'Success! Verification email is send.'
    },
    hintTexts: {
      hintCocId:
        'Chamber of Commerce reference does not need to contain a country code',
      hintEmail: 'Example: name@example.com',
      hintPhone: 'Example: +31612345678',
      hintPassword:
        'Must contain at least 8 characters, 1 of which must be a lowercase letter, 1 an uppercase letter, 1 a special character and 1 a number',
      hintPortAuthorityId: 'Enter the Port Authority ID for the organization',
      hintOrganizationName:
        'Enter the first letters of the organization that you want to couple with',
      hintPersonalIdentification:
        'Enter your personal organization identification. You can request this from the Main Administrator.',
      establishmentNumber: 'Enter the branch number here',
    },
    countries: {
      belgium: 'Belgium',
      bulgaria: 'Bulgaria',
      croatia: 'Croatia',
      cyprus: 'Cyprus',
      czechrepublic: 'Czech Republic',
      denmark: 'Denmark',
      estonia: 'Estonia',
      finland: 'Finland',
      france: 'France',
      germany: 'Germany',
      greatbritain: 'United Kingdom',
      greece: 'Greece',
      hungary: 'Hungary',
      ireland: 'Ireland',
      italy: 'Italy',
      latvia: 'Latvia',
      lithuania: 'Lithuania',
      luxembourg: 'Luxembourg',
      netherlands: 'Netherlands',
      poland: 'Poland',
      portugal: 'Portugal',
      romania: 'Romania',
      slovakia: 'Slovakia',
      slovenia: 'Slovenia',
      spain: 'Spain',
      sweden: 'Sweden',
    },
    groups: {
      all: 'All employees',
      members: 'Employees',
      admins: 'Main administrators',
      usermanagers: 'User administrators',
      suspended: 'Suspend',
      pending: 'Link request',
      changeRequest: 'Change requests',
    },
    groupStatus: {
      members: 'Member',
      admins: 'Main administrator',
      usermanagers: 'User administrator',
      suspended: 'Suspended',
      pending: 'Pending',
    },
    organizationStatus: {
      new: 'New',
      active: 'Active',
      inactive: 'Inactive',
      refused: 'Refused',
    },
    snackbar: {
      form: {
        created: 'Success!',
        updated: 'Success!',
        deleted: 'Succeeded!',
        success: 'Success!',
        error: 'Error!',
      },
    },
    help: {
      title: 'Support',
      q1: {
        title: 'I have forgotten my password, now what?',
        answer: {
          step1: 'Step 1: Go to the ',
          link1: 'Log-in page',
          step2:
            "Step 2: Click on 'Need help signing in' and then on 'Forgot password'.",
          step3:
            'Step 3: Enter your email address or your username (you can find this in the activation email that you received after creating your account).',
          step4:
            'Step 4: Click on "Reset via email". You will receive an email with the link to reset your password.',
        },
      },
      q2: {
        title: 'I have forgotten my username, what do I do now?',
        answer: {
          p1:
            'You will find your username in the activation email that you received after you created your IAMconnected account.',
          p2: 'Unable to find your user name? Then please contact the ',
          link1: 'IAMconnected service desk',
        },
      },
      q3: {
        title:
          'Are there any costs associated with using an IAMconnected account?',
        answer:
          'Creating and making use of an IAMconnected account is free-of-charge for everyone.',
      },
      q4: {
        title: 'Which services do I have access to?',
        answer:
          'You can log in after you have created your own IAMconnected account. On the start page, you can next link yourself to your organization via ‘Organization Management’. In this way, you will gain access to the services for which your organization has agreements.',
      },
    },
    contact: {
      title: 'Contact',
      content: 'For support in the use of IAMconnected you can contact us via:',
      email: 'support@iamconnected.eu',
      emailSubject: 'Contact request via IAMconnected website',
      phone: '+31 88 – 625 25 25 (between 8 a.m. and 6 p.m.)',
      emergency:
        'Outside of office hours, you can reach us on our emergency number: +316 518 844 56',
    },
    cookieBanner: {
      content:
        'Besides the fact that we really like cookies, they are also necessary for the functioning of our website. <br />We use functional and analytical cookies. ',
      readmore: 'More explanation.',
    },
    home: {
      title: 'Welcome',
      titleLoggedIn: 'Services',
      subTitleLoggedin: 'Your current services',
      subTitle:
        'Your access to digital services in port and logistics\nsimple, secure, individual, free',
       notCoupled: {
        title: 'Link your account to an organization for access to services.',
        description: 'Your account is not yet linked to an organization.',
        l1: 'Is your organization already using IAMconnected? Link your account to your organization.',
        l2: 'Is your organization not yet using IAMconnected? Have a main administrator register your organization.',
        buttonRegister: 'Register new organization',
        buttonLink: 'Link to existing organization'
      },
      introduction: {
        id: {
          title: 'One single gateway to various services',
          content: 'IAMconnected is your access point to numerous digital port-related and logistics-related services. You will no longer need to remember login details for each service. Logging in once through IAMconnected suffices.',
        },
        usage: {
          title: 'How can you participate',
          content:
            'On your first visit to IAMconnected, you create a personal identity once. This consists of a self-selected, unique username plus a strong password. You will receive a confirmation email.',
        },
        safety: {
          title: 'Security first',
          content:
            'For security reasons it is important that each person creates their own individual account. Please do not use a shared account, whereby multiple people share and use the same login credentials. Preferably use an email address with the domain name of your organization. This makes it clear you are logging in on behalf of your company.',
        },
        start: {
          title: '',
          content:
            'This is your home page for IAMconnected. Here, you will find the digital service(s) to which you have access. One click on the desired tile and you can get started right away. Do you use multiple services? Then you can always easily switch via this page.',
        },
        initial: {
          title: 'Your first visit to this start page',
          content:
            'Do you not yet see the desired digital service (s) on your home screen? You can fix this via Organization Management. Here, you can link your personal IAMconnected account to the organization you work for. The Main Administrator will next grant you the applicable rights. Your homepage will then automatically display the service(s) that are accessible to you.',
        },
      },
      services: {
        title: 'Services',
        pendingService:
        {
          title: 'Your link request must be approved to access services.',
          description: 'Your link request has not yet been approved. Please contact an administrator of your organization to be approved and gain access to services through IAMconnected.'
        },
        noServicesAvailable:
          'There are currently no services active for the selected organization.'
      },
      sectionRequestService: {
        title: 'Request services',
        description: 'Portbase and other organizations offer solutions for various target groups in the logistics chain. Find and subscribe to services to further improve your logistics process!',
        serviceButton: 'Portbase services',
        otherButton: 'Other services',
        noService: {
          title: 'Your organization does not yet have access to services',
          description: 'This can be due to the service request still being processed or not having been completed yet by Portbase. If you have recently requested services from Portbase, first check if the authorized representative in your organization has signed the contract and has sent it in. After Portbase has received the signed contract, we will prepare the requested service(s) for use. This usually takes one working day. We will keep the requester informed by e-mail.'
        }
      },
      notLoggedIn: 'You are currently not signed in.',
      notRegistered: 'Ready to get started?',
      privacy: 'Privacy Statement and General Terms & Conditions',
    },
    register: {
      title: 'Create an account',
      subTitle: 'Create your IAMconnected account on behalf of your company.',
    },
    checkedid: {
      intro: {
        title: 'CheckedID verification',
        introText: 'As part of the registration, you need to identify yourself as authorized representative. This an essential part of the safety and security within our Port Community. Your privacy will be respected.',
        content: 'The identification consists of the following three steps.<br/><br/>Please complete the identification process within 14 days. The code in this email is only valid for this amount of days.',

      },
      step1: {
        title: 'Step 1',
        introText: 'Install the CheckedID app via the regular app stores for Android or iOS.',
        content: 'The app needs access to your camera for photos of your ID document and yourself. You will be asked to turn on the NFC function, so that the chip of your ID document can be read.',
      },
      step2: {
        title: 'Step 2',
        introText: 'Use the CheckedID app to scan the invitation code below or enter the code: ',
        content: 'This code is valid for 14 days. In case the code has expired, your intended main administrator can send a new identification email.',
      },
      step3: {
        title: 'Step 3',
        introText: 'Go through the identification process in the CheckedID app. Once the identification is approved, the organization registration request will be send to you for approval.',
        content: 'For more information or needed support please see our <a href="https://support.portbase.com/en/identity-verification/">Support Page</a>.',
      }
    },
    myProfile: {
      title: 'Profile',
      verification: {
        title: 'Extra verification (MFA)',
        introText:
          'Extra verification increases your account security when signing in to IAMconnected and other services you use. For more information, see ',
        supportLink: 'Portbase support page.',
        secondIntroText:
          'New smartphone? First log in with your old smartphone, go to profile and choose an MFA reset there. After this you can reconfigure MFA for your new smartphone.',
        secondTabIntroText:
          'Cases where you may want to revoke your Google Authenticator token:',
        secondTabReason1: `Your phone was lost and you want to make sure unauthorized users can't access your account`,
        secondTabReason2:
          'You want to install Google Authenticator on a different phone',
        secondTabOutroText:
          'Do you want to revoke your existing Google Authenticator token and/or reconfigure?',
        warningModalTitle: 'Enable MFA',
        warningModalContent:
          'Once enabled, MFA cannot be disabled. Are you sure you want to continue?<br /><br /> <strong>Note:</strong> If you continue you will be automatically logged out and asked to setup MFA after logging in.',
        warningModalResetMFAContent:
          'If you continue you will be automatically logged out and asked to setup MFA after logging in.',
      },
      identification: {
        title: 'Identification',
        successMessage:
          'The status for external identification is updated again after successful completion of the regular identification process.',
        notIdentified:
          'An invitation has been sent to your email address, with the request to identify yourself. If you have not received it, click on the button below to resend it.',
      },
      personalInformation: {
        title: 'Account details',
      },
      businessInformation: {
        title: 'Business information',
        introText: `This information only applies to your currently selected organization <strong> {{name}} </strong>.`,
        changeRequest:
          'You have a pending request for changing your business information. <br />This must be approved by the main administrator, If this is no longer desired ',
      },
      businessRoles: {
        title: 'Organization roles',
        introText: `You fulfill the following role(s) within your current organization.`,
        noRolesAvailable: `There are currently no roles associated with this organization.`,
      },
      changePassword: {
        title: 'Change password',
      },
      formLabels: {
        name: 'Name',
        firstName: 'First name',
        lastName: 'Last name',
        userName: 'Username',
        primaryEmail: 'Business email address',
        mobilePhone: 'Business phone number',
        oldPassword: 'Old password',
        newPassword: 'New password',
        confirmNewPassword: 'Confirm new password',
        dateOfBirth: 'Date of birth',
        documentNumber: 'Document number',
        validityOfDocument: 'Validity of document',
        identificationStatus: 'Identification status',
      },
    },
    changeBusinessInformationDialog: {
      step1: 'Attention!',
      step2: 'Request change',
      requestButton: 'Request change',
      warning:
        'Attention! Changes must first be approved by the organization Main Administrator, before they become visible.',
      success:
        'The application is pending, the organization Main Administrator must now approve your change request. Until then, you can temporarily not use the services intended for your organization.',
    },
    requiredFieldsDialog: {
      title: 'The following is missing for {{ name }}',
    },
    newUserDialog: {
      chooseRole: {
        title: 'Welcome to IAMconnected',
        introText: `We're going to help you to quickly and easily set up your IAMconnected account.`,
        roleQuestion: `What's your role within the organization?`,
        hint: 'Select the roles that are part of your position',
      },
      chooseCoupleOrganization: {
        title: 'Search organization'
      },
      chooseNewUser: {
        intro: 'Your account is not yet coupled to an organization. <br /> A coupled organization is needed in order to request and use a service.',
        searchOrgButton: 'Search and couple yourself.',
        newOrganization: 'Register new organization',
        orgRegistration: {
          p1: 'Cannot find your organization? Please have your ',
          p2: 'main administrator',
          p3: 'and',
          p4: 'authorized representative',
          p5: 'register it first.'
        },
        adminTooltip: 'The requester of the organization registration will automatically be the main administrator of this organization. <br><br>The main administrator of an organization is amongst others responsible for managing this organization.',
        reprTooltip: 'During the organization registration process the authorized representative will be asked to identify him/herself and to approve the registration. <br><br> An authorized representative is a natural person who is authorized according to the extract of the Chamber of Commerce to sign on behalf of this organization.',
        step1: {
          title: 'Organization',
          introText: 'To which already registered organization do you belong?',
          pcsText:
            'Have you requested a service from Portbase as a new organization? You will receive an email from Portbase where you can link Portbase to IAMconnected. Click on the link in the email to migrate.',
        },
        step2: {
          title: 'Completion',
          introText: 'You are about to connect yourself to organization',
          secondIntroText:
            'Please note, the Main Administrator of this organization first needs to approve your request.',
        },
        couple: 'Link me to this organization',
        createOrganization: 'Is your organization not listed yet?',
        registerHbr: 'Register for Havenbedrijf Rotterdam?',
        registerHbrIntro:
          'Click on the button below to create your organization.',
      },
      choosePcsOrNew: {
        title: 'Welcome to IAMconnected',
        introText: `You now have an IAMconnected account, but you must be linked to at least one company to continue.`,
        buttons: {
          userHasPcs: 'Yes, migrate Portbase PCS',
          userIsNew: 'No, continue for ECT or HbR',
          newOrg: 'Create new organization',
        },
      },
      userUsesPcs: {
        step1: {
          title: 'Login to PCS',
          introText: 'Open PCS in a new tab and log in with your details.',
          secondIntroText: `On the left, at <strong>My services</strong>, you will find the heading "Link your PCS account to IAMconnected". If you click on the link you will be redirected to a special application. If this link is not there, your organization is not yet ready for IAMconnected. Please contact your Main Administrator about this.`,
          buttonLabel: 'Open PCS',
        },
        step2: {
          title: 'Login to IAMconnected',
          introText: `You will be forwarded to the migration application. In this special application you can link your PCS account to your new IAMconnected account. Click on "Login to IAMconnected.`,
        },
        step3: {
          title: 'Link the accounts',
          introText: `Click on "Link your PCS account with IAMconnected", after which the accounts will be successfully linked.`,
          secondIntroText: `When you see this screen you can close the wizard and the screen will be refreshed automatically. Your account is now ready to use.`,
        },
      },
    },
    organizationDetails: {
      title: 'Organization details',
      warningMessageSection: {
        openRequestp1: 'You have an open request. This request needs to be completed first before you can enter another request. Go to',
        openRequestp2: 'to see the current status.',
        openRequestAtDifferentOrganization: 'You still have a draft request. Go to {{ name }} in the <a href="organizations/{{orgId}}">Organization Management</a> menu to submit or cancel this request.'
      },
      companyInfoSection: {
        title: 'Newly retrieved company details will need to be validated by the authorized representative.',
        button: 'Retrieve Company Details',
        authSignatoryValidated: 'Authorized signatory has been validated',
        authSignatoryNotValidated: 'Authorized signatory is not validated',
        userNotAllowed: 'You do not have the required rights to adjust organization details. <br> Please contact your main administrator <b>{{ name }}</b> in case details need to be changed.'
      },
      externalChangeForm: {
        tekst: 'Other organization details like for instance contact person and VAT number can be changed using this ',
        link: 'change request form'
      },
      newChangeRequestp1:
        'Your change has been requested. If the authorized representative of your organization has approved the change, you will receive an activation code.',
      reprNotValidated:
        'The authorized representative has not been validated, go to contact details and choose an authorized representative.',
      formDisabledMessage:
        'Please note: the organization details can only be edited by the Main Administrator of the organization. The Main Administrator must be logged in to the organization.',
      organizationInformation: {
        title: 'Organization information',
      },
      organizationType: {
        introText: 'Select your organization type here.',
      },
      businessInformation: {
        introText: 'Enter your business information here.',
      },
      contactInformation: {
        title: 'Contact information',
        noData:
          'No Authorized representative has been found in the Chamber of Commerce, so please enter this manually. Upload additional evidence to prove that this person is authorized to sign for your organization. For example an extract from the Chamber of Commerce.',
        other: 'Manually enter an authorized representative'
      },
      identificationPreferences: {
        title: 'Enable external identification',
        toggleLabel: 'Enable external identification',
        introText:
          'Do you want to be absolutely sure that you are dealing with the right person holding the account? Then enable external identification.',
        warningText:
          'Warning: Once external identification is enabled, it cannot be disabled!',
        warningModal: {
          title: 'Enable external identification',
          warningText:
            'Warning: Once external identification is enabled, it cannot be disabled!',
          introText:
            'Do you want to be absolutely sure that you are dealing with the right person holding the account? Then enable external identification.',
          content: '',
        },
      },
      authenticationPreferences: {
        title: 'Authentication preferences',
        introText:
          'As a company, do you want absolute certainty that only the right people within your organization have access? Then enable Multi-factor Authentication (MFA). When logging in to IAMconnected, the user will be asked to enter an extra code in addition to the password. For more information see <a href="https://support.portbase.com/en/kennis/multi-factor-authentication-mfa/" target="_blank">Portbase support page.</a>',
        warningText:
          'Please note that once MFA has been enabled it cannot be disabled anymore!',
        toggleLabel: 'Enable MFA',
        warningModal: {
          title: 'Enable MFA',
          warningText:
            'Please note: Once MFA has been selected and this form is next updated, this is irreversible!',
          introText:
            'By enabling MFA, you will enable this for all employees of your organization. It is your responsibility to communicate within your organization that MFA is now required to log in.',
          content:
            'Your employees will be asked to configure MFA the next time they login.',
        },
      },
      attestationPreferences: {
        title: 'Enable periodic validation',
        introText:
          'As a company, do you want your employee and their data to be periodically validated? Then switch on Periodic Validation (Attestation). Periodic validation may be required by underlying applications that want to ensure that your employees and their data are still up to date. Periodic validation allows your employees to maintain / gain access to sensitive services and information.',
        warningText:
          'Pay attention! Once Perdiodic validation is enabled, this functionality can no longer be disabled.',
        toggleLabel: 'Enable periodic validation',
        warningModal: {
          title: 'Enable periodic validation',
          warningText:
            'Pay attention: Once Periodic Validation is enabled and this form is subsequently updated, this is irreversible!',
          introText:
            'By enabling Periodic Validation, the main administrator is periodically asked to validate the employees and their data.',
          content:
            'Periodic Validation allows your employees to maintain / gain access to sensitive services and information from underlying applications.',
        },
      },
      formLabels: {
        name: 'Organization name',
        address: 'Address',
        zipCode: 'Postal code',
        zip_code: 'Postal code',
        city: 'City',
        country: 'Country',
        country_uncode: 'Country',
        cocId: 'CoC id',
        coc_id: 'CoC id',
        coc_number: 'CoC id',
        duns: 'DUNS number',
        eoriNumber: 'EORI number',
        eoriEntry: 'EORI entry sequence number',
        eoriExit: 'EORI exit sequence number',
        eanCode: 'EAN code',
        portAuthorityId: 'Port Authority ID',
        port_authority_id: 'Port Authority ID',
        organizationAdministrator: 'Main Administrator',
        telephone_organizationAdministrator:
          'Telephone number',
        email_organizationAdministrator: 'E-mail address main administrator',
        admin_name: 'Main Administrator',
        admin_phone:
          'Telephone number',
        admin_email: 'E-mail address main administrator',
        phone: 'Phone',
        email: 'Email',
        authorizedSignatory: 'Authorized representative',
        repr_name: 'Authorized representative',
        mfaEnabled: 'Enable MFA',
        status: 'Status',
        type: 'Type',
        member_of: 'User rights',
        choose: 'Choose an organization',
        organizationType: 'Organization type',
        organization_types: 'Organization types',
        authorized_representative: 'Authorized representative',
        establishment: 'Establishment',
        telephone_authorized_representative:
          'Telephone number Authorized representative',
        email_authorized_representative:
          'E-mail address Authorized representative',
        repr_phone_number:
          'Telephone number Authorized representative',
        repr_email_address:
          'E-mail address Authorized representative',
        repr_phone:
          'Telephone number Authorized representative',
        repr_email:
          'E-mail address Authorized representative',
        rsin: 'RSIN number',
        telephoneNumber: 'Telephone number',
        telephone: 'Telephone number',
        establishmentNumber: 'Establishment number',
        est_id: 'Establishment number',
        est_number: 'Establishment number',
        date_of_birth: 'Date of birth (DD-MM-YYYY)',
        repr_date_of_birth: 'Date of birth (DD-MM-YYYY)',
        lastVerifiedDate: 'Last time validated',
        region: 'State',
        province: 'Province'
      },
      sectionOrganizationAdministrator: {
        noOrganizationAdministrator: 'Main Administrator unknown',
        changeOrganizationAdministrator: 'Change Main Administrator',
        confirmChangeOrganizationAdministrator:
          'The change can only be activated by the new main administrator after approval of the authorized representative',
        noUsers: 'There are no users for this organization',
      },
      sectionCompanyInfo: {
        title: 'Company details',
        loading: 'One moment please, we are retrieving the data',
        errorTexts: {
          changeRequest: {
            unknownResource: 'The Chamber of Commerce number is incorrect, the details cannot be updated. <br>Please contact Portbase Sales via +31 (0)88 625 25 34 to continue.',
          },
          organization: {
            invalidResourceSelected:
              'The organization details cannot be found, the details cannot be updated. <br>Please contact Portbase Sales via +31 (0)88 625 25 34 to continue.',
          },
        },
        showMore: 'Show more',
        showLess: 'Show less',
        unknown: 'Unknown',
        companyContact: 'Kontakt',
        telephoneNumber: 'telefoonnummer',
        getOrgDataButton: 'Retrieve organization data',
        internationalResults: 'Found results',
        cocNotFound:
          'The organization details with this Chamber of Commerce number were not found. Check the entry of the Chamber of Commerce number. If this still does not solve the problem, please contact Portbase Sales via +31 (0)88 625 25 34 to continue.',
        dunsNotFound:
          'The organization details were not found. Check the entry of the organization details. If this still does not solve the problem, please contact Portbase Sales via +31 (0)88 625 25 34 to continue.',
        dunsDontKnow: "I don't know my DUNS number",
        dunsDialog: {
          title: 'Search DUNS number',
          description:
            'Fill in all the fields below and press search, if your company is not found. Please contact the service desk.',
        },
        mainAdminTitle: 'Please note:',
        mainAdminNotice: 'By filling in this request you will automatically become the main administrator for this organization.',
        fileUpload: {
          title: 'Upload proof of authorized representative (jpg, pdf, png).',
          drop: 'Drag your file(s) here.',
          choose: 'Choose file',
        }
      },
      sectionContactDetails: {
        title: 'Contact details',
        authorizedrepresentative: 'Authorized representative',
        validateEmail: 'To continue first validate your emailadres',
        validateEmailButton: 'Validate email address',
      },
      sectionExtraInformation: {
        title: 'Extra information',
        rsin: 'RSIN nummer',
      },
      sectionSecurity: {
        title: 'Security',
        attestationFrequency: 'Reminder frequency of periodic validation',
        frequency: {
          oneMonth: '1 month',
          threeMonths: '3 months',
          halfYear: 'Semi-annually',
        },
      },
      sectionReferenceData: {
        title: 'Reference data',
      },
      sectionSaveInformation: 'Change request not yet submitted',
    },
    organizationTypes: {
      AGENT: 'Agent',
      BARGE_OPERATOR: 'Barge operator',
      EMPTY_DEPOT: 'Empty depot',
      FORWARDER: 'Forwarder',
      EXPORTER: 'Exporter',
      IMPORTER: 'Importer',
      INSPECTION_STATION: 'Inspection station',
      SHIPBROKER: 'Shipbroker, Shipping company',
      SKIPPER: 'Skipper',
      RAIL_OPERATOR: 'Rail operator, rail haulier',
      TERMINAL: 'Terminal',
      ROAD_HAULIER: 'Road haulier',
      CUSTOMS: 'Customs',
      FOOD_HEALTH_SERVICE: 'Food and health service',
      PORT_AUTHORITY: 'Port authority',
      RAILWAY_AUTHORITY: 'Railway authority',
      IT_SERVICE_PROVIDER: 'IT Service provider',
    },
    organizationRoles: {
      ADMIN: 'Main Administrator',
      BACK_OFFICE: 'Back office',
      BARGE_OPERATOR: 'Barge operator',
      BARGE_TERMINAL_OPERATOR: 'Barge terminal operator',
      BARGE_VIEWER: 'Barge viewer',
      BOATMEN: 'Boatmen',
      CAPTAIN: 'Captain',
      CARGO_HANDLING_AGENT: 'Cargo handling agent',
      CARGO_TRACKER_ORG: 'Cargo tracker organization',
      CARRIER: 'Carrier',
      CONTAINER_LESSOR: 'Container lessor',
      CONTAINER_OPERATOR: 'Container operator',
      CUSTOMS: 'Customs',
      DEPOT_OPERATOR: 'Depot operator',
      DEVELOPER: 'Developer',
      FINANCIAL_DECLARANT: 'Financial declarant',
      FORMER_SHIP_OPERATOR: 'Former ship operator',
      FORWARDER: 'Forwarder',
      FUTURE_SHIP_OPERATOR: 'Future ship operator',
      HELP_DESK: 'Help desk',
      INSPECTION_LOCATION: 'Inspection location',
      INTEGRAL_PLANNER: 'Integral planner',
      MVL_DECLARANT: 'MVL declarant',
      ORG_ADMIN: 'Organization Main Administrator',
      PILOT: 'Pilot',
      PORT_AUTHORITY: 'Port authority',
      RAIL_OPERATOR: 'Rail operator',
      RAIL_TERMINAL_OPERATOR: 'Rail terminal operator',
      RAIL_VIEWER: 'Rail viewer',
      RAILWAY_AUTHORITY: 'Railway authority',
      REGISTRANT: 'Registrant',
      ROAD_HAULIER: 'Road haulier',
      ROAD_TERMINAL_OPERATOR: 'Road terminal operator',
      ROAD_VIEWER: 'Road viewer',
      SHIP_OPERATOR: 'Ship operator',
      TERMINAL_OPERATOR: 'Terminal operator',
      TOWAGE: 'Towage',
      TRACTION_SUPPLIER: 'Traction supplier',
      VIP_INSPECTION_REQUESTER: 'VIP inspection requestor',
      VIP_MVL_ADMIN: 'VIP/MVL Main Administrator',
      VWA: 'VWA',
      WASTE_COLLECTOR: 'Waste collector',
    },
    organizationsManagement: {
      title: 'Organization Management',
      tabs: {
        coupleWithOrganization: 'Couple with additional organization',
        myOrganizations: 'My organizations',
        addOrganization: 'Register a new organization',
        finishRequest: 'Complete registration',
      },
      dialog: {
        title: 'Decouple',
        content:
          'Your account will be removed for {{ orgName }}. Your account will stay active for the other organizations.',
        content_main: 'Your account will be removed',
        content_main_multiple_orgs:
          'Your account will be removed for {{ orgName }} and the other coupled organizations.',
      },
      tooltip: {
        delete: 'Decouple the user from the organization',
      },
      overview: {
        noDataToDisplay:
          'You are not currently coupled with an organization. Use the button below to do so.',
      },
    },
    organizationsCoupling: {
      title: 'Couple with an additional organization',
      content:
        'Couple request for additional organization. As a user, you can do work for an additional organization. To do this, you submit a couple request for an additional organization. The administrator of the additional organization must approve this couple request.',
      formLabels: {
        organizationName: 'Organization name',
        organizationRoles: 'Organization role(s)',
      },
      attestation: {
        status: 'Validated',
        valid: 'Your account has been validated by the administrator',
        invalid:
          'The administrator of the organization must validate your account',
        validated_date: 'Last time validated',
      },
    },
    openRequests: {
      title: 'Open requests',
      organization: 'Organization',
      activationCode: 'activation code',
      noOpenRequests: 'There are currently no open requests',
      verifyEmail: 'Validate email address',
      changeUserProfile: 'Change Profile',
      description: {
        verifyEmail: 'There is a pending change request to validate your email address.',
        changeUserProfile: 'There is a pending change request for your profile.'
      },
      requestWithStatusNew: {
        p1: 'You still have a draft request. Go to',
        p2: 'to submit or cancel this request.'
      },
      cancelRequest:
        'If the registration request is no longer needed, click here.',
      activate: 'activate',
      newOrganization: 'new organization',
      changeOrganizationDetails: 'change organization details',
      changeOrganizationReferenceData: 'change organization reference data',
      changeOrganizationRepresentative: 'change authorized representative',
      changeOrganizationAdmin: 'change organization main administrator',
      changeOrganizationType: 'change organization type',
      validateOrganizationDetails: 'validate organization details',
      refreshOrganizationDetails: 'refresh organization details',
      newService: 'New service',
      editService: 'Change service',
      changeBusinessInformation: 'Change business information',
      updateOrganizationDetails: 'Change organization data',
      sectionProcessInsights: {
        createOrg: {
          title: 'Status organization registration request',
          step1: {
            title:'Identification',
            content: 'This request requires the identification of the authorized representative. Your authorized representative has received an email with instructions.',
            button: 'Resend identification email'
          },
          step2: {
            title: 'Review',
            content: 'Portbase will review your request.'
          },
          step3: {
            title: 'Approve',
            content: 'An invitation to sign the contract has been sent to the authorized representative by email.',
            button: 'Resend invitation email'
          }
        },
        changeOrgDetails:{
          title: 'Change organization data',
          step1: {
            title: 'Identification',
            content: 'This change request requires the (re)identification of the authorized representative. Your authorized representative has received an email with instructions. '
          },
          step2: {
            title: 'Review',
            content: 'Portbase will review your change request.'
          },
          step3: {
            title: 'Approve',
            content: 'An invitation to sign the contract has been sent to the authorized representative by email.'
          },
          footer: 'For more information please see our <a href="https://support.portbase.com/en/iamconnected/">Support Page</a>;',
          sectionRequestDetails: {
            current: 'Current',
            new: 'New'
          }
        }
      }
    },
    userManagement: {
      title: 'User Management',
      filter: 'Filter users',
      warningMessages: {
        emailNotVerified:
          'This user has not verified his email address. The user can verify his email address by using the link in the email we have sent. <br />The user should click on the verification link in the email within 24 hours otherwise this change request will be cancelled.',
        deleteUserAccount:
          'Are you sure you want to delete this user? The user account will be deleted.<br />As a result, the account can no longer be used for other organizations.',
        decoupleUserAccount:
          'Are you sure you want to remove this user from your organization? The user will be disconnected from your organization.',
      },
      periodicValidation: {
        selectEmployees: 'Select employees in the list',
        quarterLabel1: 'January - March',
        quarterLabel2: 'April - June',
        quarterLabel3: 'July - September',
        quarterLabel4: 'October - December',
        quarter: ' Quarter ',
        history: {
          title: 'Previous reviews',
          numberOfEmployees: '{{amount}} out of {{total}} employees ',
          numberOfEmployeesCompleted: '{{total}} employees ',
          new: 'Not completed',
          not_completed: 'Not completed',
          completed: 'Completed',
          review: 'Review',
          status: 'Status',
          number: 'Number',
          validatedBy: 'Validated by',
        },
        completed: 'completed',
        lastLogin: 'en{{date}}',
        notificationBar: {
          title: 'The quarterly review is open',
          description: 'There are {{count}} employees to validate.',
          titleUsermanager: 'The quarterly review is almost completed.',
          descriptionUsermanager: 'Please ask your main administrator to validate the user administrator(s).',
          button: 'Start review',
          descriptionOpen: 'Please validate all your employees for completion.',
          titleOpen: 'You have an ongoing quarterly review',
          buttonOpen: 'Continue review',
        },
        feedback: {
          success: {
            title: '{{count}} employees validated',
            singleTitle: '1 employee validated',
          },
          alreadyCompleted: {
            title: 'The quarterly review has already been completed',
            message: 'Another administrator beat you to it.',
          },
          userAdminRestriction: {
            title: 'Only the main administrator can validate user administrators',
            message: 'As a user administrator you cannot validate other user administrators. Ask your main administrator to help complete the quarterly review.',
          },
          featureUnavailable: {
            title: 'This feature is currently unavailable',
            message: 'Please try again later.',
          },
        },
        dialog: {
          header: 'Validate all employees',
          content: 'You are about to validate all employees at once. Only do this if you are sure everyone is allowed to keep access.',
          submit: 'Validate all employees'
        }
      },
      actions: {
        approve: 'Allow the user to be coupled with the organization',
        reject: 'Reject the user to be coupled with the organization',
        suspend: 'Suspend the user from the organization',
        attestation: 'Validate the data of this user',
        unsuspend: 'Restore user rights for the organization',
        delete: 'Delete user from this organization',
        usermanagers: 'Allow the user to perform user rights management',
        removeUsermanagers:
          'Disallow the user to perform user rights management',
        resetMFA: 'Reset Multi Factor Authentication',
        searchEmployee: 'Search employee',
        accessLevel: 'Access level',
        organizationAccess: 'Organization access'
      },
      buttons: {
        makeUserManager: 'Allow user rights',
        suspend: 'Suspend user',
        attestation: 'Validate user',
        delete: 'Delete user',
        unsuspend: 'Unsuspend user',
        removeUserManager: 'Disallow user rights',
        downloadMemberOverview: 'Download user overview',
        resetMFA: 'Reset MFA',
        validateEmployee: 'Validate employee',
        validateEmployeeSingle: 'Validate 1 employee',
        validateEmployeePlural: 'Validate {{count}} employees',
        recover: 'Recover'
      },
      tabs: {
        quarterlyReview: 'Quarterly reviews'
      },
      tables: {
        firstName: 'First name',
        last_login: 'Last logged in',
        lastName: 'Last name',
        login: 'User name',
        date_submitted: 'Date submitted',
        date_approved: 'Date approved',
        actions: 'Actions',
        personalIdentification: 'Personal identification',
        businessEmail: 'Business email address',
        businessPhone: 'Business phone number',
        roles: 'Roles',
        successFullyIdentified: 'The user has been successfully identified',
        notSuccessFullyIdentified:
          'The user has not been successfully identified',
        emailValidated: 'Business email address is validated',
        emailNotValidated: 'Business email is not validated',
        userNotIdentified:
          'Attention! this user cannot be accepted yet because he / she has not yet identified himself.',
        expiry: 'End date (optional)',
        personalInformation: 'Personal information'
      },
    },
    serviceManagement: {
      title: 'Service Management',
      data: 'Data',
      example: 'Example',
      logo: 'Logo',
      serviceOverview: {
        title: 'Services offered by my organization',
        newService: 'Offering a new service',
      },
      imageUpload: {
        title:
          '<strong>Upload Logo</strong><br />Maximum 80x80 pixels and jpg or png format.',
        delete: 'Delete Logo',
        maxImageSize: 'Maximum image size 1MB',
        fileType: 'Only images are allowed (JPG | PNG)',
        maxImageResolution: 'Maximum image size 80*80px',
      },
      formLabels: {
        name: 'Name',
        title: 'Title',
        logo: 'Logo',
        redirect_uri: 'Redirect uri',
        description: 'Description',
        link_url: 'Link URL',
        window_target: 'Window Target',
        client_id: 'Client ID',
      },
      success:
        'Success! There is an open request at Portbase for this new service',
      openRequest:
        'Je hebt al een openverzoek, deze dient eerst afgerond te worden voordat je deze service kan wijzigen.',
      tabData: 'Data',
      tabAccessControl: {
        title: 'Access to my service',
        accessToMyService: 'Access to my service',
        accessToMyServiceSubtitle:
          'The list of organizations below have access to your service.',
        buttonRevokeAccess: 'Deny access',
        giveAccessToMyService: 'Give an organization access to my service',
        giveAccessToMyServiceSubtitle:
          'Search for an organization to give it access to your service.',
        searchCompanyInputFieldHint: 'Search for an organization',
        searchResults: 'Search results:',
        searchNoResults: 'No organizations found',
        buttonGiveAccess: 'Give access',
      },
    },
    changeOrganizationAdministrator: {
      title: 'Change Main Administrator',
      introText: {
        firstParagraph:
          'To change the Main Administrator, you must first select and submit another Main Administrator.',
        secondParagraph: 'Please note: you must also fill in the',
        changeFormLinkUrl: 'https://www.portbase.com/en/form/change-form/',
        changeFormLinkText: 'Portbase change form to be completed.',
        thirdParagraph:
          'The change will only be implemented after approval from our sales department.',
      },
      form: {
        title: 'Change Main Administrator',
        introText: 'Select a new Main Administrator from the list below.',
        autoCompletePlaceholder: 'Find an employee',
        submitButton: 'Submit request',
      },
      confirmNewOrganizationAdministrator: {
        firstParagraph:
          'You are about to change the Main Administrator of the organization',
        secondParagraph:
          'Are you sure you want to change the Main Administrator?',
      },
    },
    newOrganizationAdministrator: {
      title: 'New Main Administrator',
      tooltip: 'Pending approval by Portbase',
    },
    news: {
      title: 'News',
      subTitle: 'Read up on current developments',
      items: [
        {
          title: 'New feature: this newsfeed',
          details: 'Announcements and recent developments will be shown here',
        },
      ],
    },
    feedback: {
      title: 'Feedback',
      button: 'Contact customer service',
      description1:
        'The Portbase Customer Service is available 24/7. Our professionals can help you in Dutch, English and German.',
      description2:
        'Portbase Customer Service is your central point of contact for all your questions about our services. You can contact our team of trained and specialised staff for the answers to all your questions and with all your issues. To ensure efficient handling, you can contact us directly about any question and we will try to handle your request for assistance immediately.',
      allowContact: 'IAMconnected can contact me',
      success: 'Success, your request for help will be handled by customer service!',
    },
    changeBusinessInformationRequestTypes: {
      change_business_information: 'Change of business information',
      change_user_profile: 'Change of user profile',
    },
    verifyEmailPage: {
      title: 'Email verification',
      success: 'Thank you, your email address has been verified.',
      error: 'Something went wrong while verifying the email address.',
      errorEmailAlreadyVerified:
        'The email address has already been verified, you do not need to take any further action.',
      errorLoggedin:
        'The verification link has expired, request another verification email.',
      errorNotLoggedin:
        'The verification link has expired, login and request a verification email again.',
      errorLoggedinButton: 'Resend verification email',
    },
    migratingBusinessInformation: {
      sectionChangeEmail: {
        title: 'Change data',
        description:
          'Enter your new details below, a change request will be sent to your main administrator. After approval from your main administrator, the changes will be active.',
        emailField: 'Your business email address',
        phoneField: 'Your business phone number',
      },
      sectionEmailSend: {
        title: 'A verification email has been send',
        description:
          'You received a verification link on {{businessEmail}} Click the link in the email to verify your email address. In case you did not receive this email, check your spam folder.',
        button: 'Send verification link again',
        cancel: 'Wrong email address? Click here'
      },
      sectionVerifyEmail: {
        title: 'Verify your email address',
        description:
          'All accounts now need to have one unique email address to increase the safety of IAMconnected. This is the business email address associated with your organization.',
        question: 'Which email address needs to be linked to your account?',
        button: 'Verify',
        attention: 'Group email addresses are strongly disadvised. This email address is used to enable changes in your account.  '
      },
    },
    pageAcceptTermsAndConditions: {
      registration: {
        title: 'Organization registration request for',
        subTitle: 'We have received an organization registration request from ',
        subTitle2: 'This registration request will only be processed after approval and signing by you, as the registered authorized representative of this organization.'

      },
      change: {
        title: 'Organization change request for',
        subTitle: 'We have received an organization change request from ',
        subTitle2: 'This change request will only be processed after approval and signing by you, as the registered authorized representative of this organization.'
      },
      error: {
        notFound: 'This link is no longer valid. The request may have been removed or already been accepted.',
      },
      supportPage: 'For more information please see our <a target="_blank" href="https://support.portbase.com/en/iamconnected/"> Support Pages</a>',
      success: 'Your request has been successfully completed, you will receive a confirmation email. ',
      table: {
        header: {
          col1: 'Old Details',
          col2: 'New Details',
        }
      },
      termsAndConditions: {
        authorizedRepr: 'I am the <a href="https://support.portbase.com/en/iamconnected/#authorized-signatory" class="text-blue-A100" target="_blank">authorized representative</a> of organization ',
        GeneralTermsAndConditions: 'I agree with the <a href="https://www.portbase.com/en/general-terms-conditions/" target="_blank" class="text-blue-A100">General Terms & Conditions</a>',
        PatricipantConditions: 'I agree with the <a href="https://www.portbase.com/wp-content/uploads/2023/05/1205-IAMconnected-Terms-and-Conditions-for-Participants-3.pdf" target="_blank" class="text-blue-A100">Terms & Conditions</a>',
        mainAdministrator: ' is the <a href="https://support.portbase.com/en/iamconnected/#top-level-admin" target="_blank" class="text-blue-A100">main administrator</a> of my organization',
        acceptThisChange: 'I agree with this request',
        acceptAll: 'You must accept all the Terms and Conditions',
        okeAndSend: 'Approve'
      }
    }
  };
}
