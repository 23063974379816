/* eslint-disable max-len */
import {sharedTranslations} from '../../libs/translations/nl';

export class Nederlands {
  static translations = {
    sharedTranslations,
    popups: {
      deactivateUser: {
        title: 'Bevestig deactivering',
        text:
          'Weet je zeker dat je je profiel wilt deactiveren? Al je gegevens worden dan gewist. Om weer via IAMconnected te werken, moet je opnieuw een account aanmaken.',
      },
      backToMainMenuNewOrg: {
        title: 'Terug naar het hoofdmenu?',
        text:
          'Wil je terug naar het hoofdmenu (de gegevens van het bedrijf blijven bewaard) of wil je een ander bedrijf opzoeken?',
        confirm: 'Terug naar het hoofdmenu',
        cancel: 'Ander bedrijf opzoeken',
      },
    },
    buttons: {
      send: 'Versturen',
      delete: 'Verwijderen',
      reset: 'Resetten',
      cancel: 'Annuleren',
      cancelRequest: 'Annuleer aanvraag',
      ok: 'Ok',
      submit: 'Indienen',
      update: 'Bijwerken',
      change: 'Wijzigen',
      create: 'Aanmaken',
      register: 'Account aanmaken',
      activate: 'Activeren',
      deactivate: 'Deactiveren',
      save: 'Opslaan',
      login: 'Inloggen',
      logout: 'Uitloggen',
      close: 'Sluiten',
      back: 'Terug',
      next: 'Volgende',
      postponeRequest: 'Voltooi later',
      toHome: 'Naar de home pagina',
      toLogin: 'Naar de login pagina',
      resetToken: 'Token instellen',
      editPersonalInformation: 'Bewerk persoonlijke informatie',
      changePassword: 'Wachtwoord wijzigen',
      changeInformation: 'Gegevens wijzigen',
      addOrganization: 'Nieuwe organisatie aanmelden',
      signUpWithOrganization: 'Bij organisatie aanmelden',
      coupleWithOrganization: 'Koppel mij aan deze organisatie',
      homeLogin: 'Direct inloggen in IAMconnected',
      homeRegister: 'Maak nu je eigen IAMconnected account',
      accept: 'Accepteren',
      deny: 'Weigeren',
      expandAll: 'Alles uitklappen',
      collapseAll: 'Alles inklappen',
      moreInformation: 'Meer gegevens',
      lessInformation: 'Minder gegevens',
      search: 'Zoeken',
      refreshData: 'Gegevens verversen',
      changeRequest: 'Wijziging aanvragen',
      validateData: 'Gegevens valideren',
      requestNewOrganization: 'Nieuwe organisatie aanvragen',
      identifyAgain: 'Opnieuw identificeren',
      resendIdentify: 'Uitnodiging opnieuw versturen',
      clickHere: 'Klik hier',
      yes: 'Ja',
      noEdit: 'Nee, wijzig gegevens',
      cancelOpenRequest: 'Open verzoek annuleren',
      detailOpenRequest: 'Details Open Verzoek',
      confirm: 'Bevestigen',
    },
    tooltips: {
      validated: 'Gevalideerd',
      notValidated: 'Ongevalideerd in de laatste 3 maanden',
      validationNotRequired: 'Validatie niet nodig',
      validationByAdmin: 'Valideer door hoofdbeheerder'
    },
    icons: {
      simple: 'eenvoudig',
      safe: 'veilig',
      personal: 'persoonlijk',
      free: 'gratis',
    },
    warningBox: {
      openRequestWarning: {
        content:
          'Je kunt geen wijziging meer aanvragen omdat je al een open verzoek hebt.',
        button: 'Open verzoek annuleren',
      },
    },
    errorTexts: {
      errorSnackbar: {
        title: 'Er is een onverwachte fout opgetreden.',
        mention: 'Klik hier om hiervan melding te maken bij onze',
        error: 'Foutcode: '
      },
      authorization: {
        nonUniqueBusinessEmail: 'Dit e-mailadres wordt al gebruikt',
        missingBusinessInformation: 'Zakelijke informatie ontbreekt',
        missingBusinessIdentification:
          'Persoonlijke bedrijfsidentificatie ontbreekt',
        missingBusinessPhone: 'Zakelijk telefoonnummer ontbreekt',
        missingBusinessEmail: 'Zakelijk e-mailadres ontbreekt',
      },
      general: {
        title: 'Error',
        badRequest:
          'Helaas is er iets misgegaan. Controleer de ingevulde gegevens en probeer het opnieuw.',
        forbidden: 'Je hebt geen rechten.',
        unknown: `Helaas is er iets misgegaan. Probeer het nog een keer.`,
        internalServerError: `Helaas is er iets misgegaan. Probeer het nog een keer.`,
        missingInformation: 'De volgende informatie ontbreekt nog',
        httpStatusCodes: {
          '0': 'Service niet bereikbaar',
          '401':
            'Je bent op dit moment niet (meer) ingelogd. Je wordt doorverwezen naar de login pagina.',
          '403': 'Je bent niet geautoriseerd voor deze functionaliteit.',
          '404': 'Een resource werd niet gevonden.',
          '500': 'Er is iets mis gegaan',
          '503': 'Service niet beschikbaar',
        },
        required: 'Vul aub een waarde in',
        validationFailed: 'De activatie code is helaas niet correct',
      },
      user: {
        operationNotAllowed: 'Deze actie is niet toegestaan',
        userNameAlreadyExists: 'Deze gebruikersnaam bestaat al',
        notAllowedToConnectToThisOrganization:
          'Alleen Portbase medewerkers kunnen zich bij deze oranisatie aanmelden',
        updateCredentialsFailed:
          'Het nieuwe wachtwoord mag niet hetzelfde zijn als het huidige wachtwoord of het oude wachtwoord is incorrect ingevuld. Probeer het opnieuw.',
        invalidUsername: 'De ingevoerde gebruikersnaam is niet geldig',
        usernameIsEmailAddress:
          'De ingevoerde gebruikersnaam mag geen emailadres zijn',
        emailNotVerified: 'Emailadres is nog niet geverifieerd',
      },
      notAccepted: 'Er moet akkoord gegaan worden met de voorwaarden',
      notAlphaNumeric: 'Waarde is niet alfanumeriek',
      notNumeric: 'Alleen numerieke waarden zijn toegestaan',
      notEmail: 'Incorrect e-mailadres',
      notPhone: 'Waarde is geen correct telefoonnummer',
      notOldPassword: 'Waarde is geen correct oud wachtwoord',
      notPassword: 'Waarde is geen correct wachtwoord',
      notMatchPassword: 'Wachtwoorden komen niet overeen',
      notMaxLength: 'Waarde mag maximaal {{ requiredLength }} tekens bevatten',
      notMinLength: 'Waarde moet minimaal {{ requiredLength }} tekens bevatten',
      noDataToDisplay: 'Geen gegevens gevonden om te tonen',
      noFilterResultsFound:
        'Geen resultaten gevonden die voldoen aan jouw criteria - pas het filter aan',
      noMainOrganization:
        'Koppelen aan deze organisatie is momenteel niet mogelijk',
      notFoundPage: {
        header: 'Niet gevonden',
        body:
          'De opgevraagde pagina kon niet gevonden worden. Als je denkt dat dit niet klopt, gelieve dit te melden bij jouw hoofdbeheerder.',
      },
      requiredFields: 'Met * gemerkte velden zijn verplicht om in te vullen.',
      cookiesDisabled: {
        title: 'Cookies staan uit',
        body:
          'Deze website heeft functionele cookies nodig om te werken. Omdat cookies in jouw browser lijken te zijn uitgeschakeld, kun je IAMconnected niet gebruiken.',
      },
      organization: {
        cocIdAlreadyExists:
          'Een organisatie met dit KvK-nummer / Duns nummer bestaat reeds.',
        operationNotAllowed: 'Deze actie is niet toegestaan',
        alreadyExists: 'Organisatie bestaat reeds',
        unknownResource: 'Gegevens niet gevonden, controleer ingave.',
      },
      organizationType: {
        invalidType: 'Inactieve organisatie type geselecteerd',
      },
      register: '',
      changeRequest: {
        unknownResource: 'Gegevens niet gevonden, controleer ingave.',
        invalidResourceSelected: 'Gegevens niet gevonden, controleer ingave.',
        alreadyExists: 'Wijzigingsverzoek bestaat reeds',
        activeChangeRequest: 'Je contactgegevens worden momenteel gewijzigd. Deze heb je nodig voor het aanmelden van een organisatie.',
        activeNewOrgRequest: 'Er loopt al een aanmelding.',
        invalidAction:
          'Er is een onverwachte fout opgetreden.',
        invalidRepresentative:
          'De tekenbevoegde heeft zich nog niet geidentificeerd, dit moet eerst gedaan worden voordat deze wijziging aangevraagd kan worden',
        activateNotAllowed:
          'Deze activatie code kan alleen ingevoerd worden door de nieuwe hoofdbeheerder',
      },
      emailNotVerified:
        'Valideer eerst je emailadres, ga naar profiel en klik op Emailadres valideren.',
      validateByAdmin: 'Validatie door hoofdbeheerder',
      fileUpload: {
        fileSize: {
          title: 'Te groot bestand',
          content: 'De maximaal toegestane grootte is 50MB.',
          tooltip: 'Denk hierbij aan een uittreksel van het KvK of handelsregister. <br>Toegestane bestandstypen zijn pdf, jpg en jpeg.'
        },
        scan: {
          title: 'Probleem bij scannen bestand',
          content: 'Als dit probleem blijft voortduren, neem dan contact op met onze Sales afdeling via <u>sales@portbase.com</u>'
        },
        general: {
          title: 'Probleem bij het uploaden',
          content: 'Als dit probleem blijft voortduren, neem dan contact op met onze Sales afdeling via <u>sales@portbase.com</u>'
        }
      },
      bouncedEmail: {
        hardBounce: '{{email}} is permanent onbereikbaar. <br>Pas het e-mailadres aan, of neem contact op met de e-mailprovider of helpdesk van je eigen organisatie om dit probleem op te lossen. Neem daarna contact op met <a href="{{supportUrl}}" target="_blank">Customer Service</a> om {{email}} als hersteld te laten noteren. Dit is nodig om weer e-mails te kunnen ontvangen.',
        softBounce: '{{email}} is momenteel niet bereikbaar. <br>Controleer het e-mailadres. Neem eventueel contact op met de e-mailprovider of helpdesk van je eigen organisatie als dit probleem zich blijft voordoen.',
        mailboxFull: 'De inbox van {{email}} is te vol om e-mails te ontvangen. Vraag je tekenbevoegde om deze inbox te legen.',
        authorizedRepr: {
          hardBounce: 'De opgegeven tekenbevoegde is permanent niet bereikbaar via {{email}}. <br>Annuleer deze aanvraag, en doe een nieuwe aanvraag met een ander e-mailadres voor de tekenbevoegde.',
          softBounce: 'De opgegeven tekenbevoegde is niet bereikbaar via {{email}}. <br>Los dit op via de e-mailprovider of helpdesk van je eigen organisatie. Of annuleer deze aanvraag, en maak een nieuwe aanvraag met andere contactgegevens.',
          mailboxFull: 'De inbox van {{email}} is te vol om e-mails te ontvangen. Vraag je tekenbevoegde om deze inbox te legen.',
        }
      }
    },
    successTexts: {
      general: {
        title: 'Gelukt!',
        cancelActivationCode: 'De aanvraag is verwijderd',
      },
      couple:
        'Gelukt, je krijgt een mail als je bent geaccepteerd door de hoofdbeheerder.',
      register:
        'Gelukt! Je ontvangt een e-mail voor het activeren van je account.',
      identification:
        'Gelukt! Je ontvangt een e-mail om jezelf opnieuw te identificeren',
      updateUser: 'Gelukt! Je profiel is aangepast.',
      coupleOrganization:
        'Gelukt, Zodra jouw hoofdbeheerder je de benodigde rechten heeft toegekend, zie je op je startpagina van IAMconnected de dienst(en) waartoe je toegang hebt.',
      createOrganization:
        'Gelukt, je hebt je organisatie succesvol aangemeld voor IAMconnected. De tekenbevoegde zal via email uitgenodigd worden om zich te identificeren. Na goedkeuring door de tekenbevoegde zal je de registratie kunnen activeren.',
      updateOrganization: 'Gelukt, je hebt je organisatie succesvol gewijzigd.',
      changeRequestSuccess:
        'Je wijzigingsverzoek is aangevraagd. Je ontvangt binnenkort een activatie code om je open verzoek te activeren.',
      submitActivationCode: 'De organisatie is nu actief',
      cancelActivationCode: 'De aanvraag is verwijderd',
      changeRequest: 'Gelukt! aanvraag is ingediend.',
      resendIdentification: 'Gelukt! Er is een email verstuurd naar de tekenbevoegde: ',
      verifyEmail: 'Gelukt! Verificatie email verstuurd.'
    },
    hintTexts: {
      hintCocId: 'KvK nummer hoeft geen landcode te bevatten',
      hintEmail: 'Voorbeeld: naam@voorbeeld.com',
      hintPhone: 'Voorbeeld: +31612345678',
      hintPassword:
        'Minimaal 8 tekens, waarvan 1 kleine letter, 1 hoofdletter, 1 symbool en 1 cijfer',
      hintPortAuthorityId: 'Voer de Port Authority Id voor de organisatie in',
      hintOrganizationName:
        'Voer de eerste letters in van de organisatie waarmee je wilt koppelen',
      hintPersonalIdentification:
        'Voer je persoonlijke bedrijfsidentificatie in. Deze kun je opvragen bij je hoofdbeheerder.',
      establishmentNumber: 'Voer hier het vestigingsnummer in',
      birth_date: 'Voer hier de geboortedatum in van de tekenbevoegde',
      birth_place: 'Voer hier de geboorteplaats in van de tekenbevoegde',
    },
    countries: {
      belgium: 'Belgie',
      bulgaria: 'Bulgarije',
      croatia: 'Kroatie',
      cyprus: 'Cyprus',
      czechrepublic: 'Tsjechië',
      denmark: 'Denemarken',
      estonia: 'Estland',
      finland: 'Finland',
      france: 'Frankrijk',
      germany: 'Duitsland',
      greatbritain: 'Groot-Brittannië',
      greece: 'Griekenland',
      hungary: 'Hongarije',
      ireland: 'Ierland',
      italy: 'Italië',
      lithuania: 'Litouwen',
      luxembourg: 'Luxemburg',
      latvia: 'Letland',
      netherlands: 'Nederland',
      poland: 'Polen',
      portugal: 'Portugal',
      romania: 'Roemenië',
      spain: 'Spanje',
      slovenia: 'Slovenië',
      slovakia: 'Slowakije',
      sweden: 'Zweden',
    },
    groups: {
      all: 'Alle medewerkers',
      members: 'Medewerkers',
      admins: 'Hoofdbeheerders',
      usermanagers: 'Gebruikersbeheerders',
      suspended: 'Op non-actief',
      pending: 'Koppelverzoek',
      changeRequest: 'Wijzigingsverzoek',
    },
    groupStatus: {
      members: 'Medewerker',
      admins: 'Hoofdbeheerder',
      usermanagers: 'Gebruikersbeheerder',
      suspended: 'Non-actief',
      pending: 'In behandeling',
    },
    organizationStatus: {
      new: 'Nieuw',
      active: 'Actief',
      inactive: 'Inactief',
      refused: 'Geweigerd',
    },
    snackbar: {
      form: {
        created: 'Gelukt!',
        updated: 'Gelukt!',
        deleted: 'Gelukt!',
        success: 'Gelukt!',
        error: 'Mislukt!',
      },
    },
    help: {
      title: 'Support',
      q1: {
        title: 'Ik ben mijn wachtwoord vergeten, wat nu?',
        answer: {
          step1: 'Stap 1: Ga naar de ',
          link1: 'login pagina',
          step2:
            "Stap 2: Klik op 'Hulp nodig bij het aanmelden?' en daarna op 'Wachtwoord vergeten?'.",
          step3:
            'Stap 3: Voer je gebruikersnaam in (deze vind je in de activatiemail die je hebt ontvangen na het aanmaken van je account)',
          step4:
            "Stap 4: Klik op 'Reset via e-mail'. Je ontvangt een e-mail met hierin de link om je wachtwoord opnieuw in te stellen.",
        },
      },
      q2: {
        title: 'Ik ben mijn gebruikersnaam vergeten, wat doe ik nu?',
        answer: {
          p1:
            'Je vindt je gebruikersnaam in de activatiemail die je hebt ontvangen na het aanmaken van je IAMconnected account.',
          p2:
            'Kun je je gebruikersnaam niet vinden? Neem dan contact op met de ',
          link1: 'IAMconnected servicedesk',
        },
      },
      q3: {
        title:
          'Zijn er kosten verbonden aan het gebruik van een IAMconnected account?',
        answer:
          'Het aanmaken en het gebruik van een IAMconnected account is voor iedereen gratis.',
      },
      q4: {
        title: 'Tot welke diensten heb ik toegang?',
        answer:
          'Nadat je een eigen IAMconnected account hebt aangemaakt, kun je inloggen. Op je startpagina koppel je jezelf daarna via ‘Organisatiebeheer’ aan je organisatie. Zo krijg je toegang tot de diensten waarmee jouw organisatie afspraken heeft.',
      },
    },
    contact: {
      title: 'Contact',
      content:
        'Voor ondersteuning bij het gebruik van IAMconnected kun je ons bereiken via:',
      email: 'support@iamconnected.eu',
      emailSubject: 'Contactaanvraag via IAMconnected website',
      phone: '+31 88 – 625 25 25 (tussen 08:00 en 18:00 uur)',
      emergency:
        'Buiten onze kantooruren zijn wij bereikbaar op ons spoednummer +316 518 844 56',
    },
    cookieBanner: {
      content:
        'Naast het feit dat wij cookies erg lekker vinden, zijn ze ook noodzakelijk voor het functioneren van onze website. <br />Wij maken gebruik van functionele en analytische cookies. ',
      readmore: 'Meer uitleg',
    },
    home: {
      title: 'Welkom',
      titleLoggedIn: 'Services',
      subTitle:
        'Jouw toegang tot digitale diensten in haven en logistiek\neenvoudig, veilig, individueel, gratis',
      subTitleLoggedin: 'Je huidige services',
      notCoupled: {
        title: 'Koppel je account aan een organisatie voor toegang tot services',
        description: 'Je account is nog niet gekoppeld aan een organisatie.',
        l1: 'Maakt je organisatie al gebruik van IAMconnected? Koppel je account dan aan je organisatie.',
        l2: 'Maakt jouw organisatie nog geen gebruik van IAMconnected? Laat een hoofdbeheerder je organisatie registreren.',
        buttonRegister: 'Registreer nieuwe organisatie',
        buttonLink: 'Koppel aan bestaande organisatie'
      },
      introduction: {
        id: {
          title: 'Eén ingang voor meerdere diensten',
          content: 'IAMconnected is jouw toegang tot meerdere digitale diensten in haven en logistiek. Je hoeft niet meer voor elke dienst apart inloggegevens te onthouden. Eénmaal inloggen via IAMconnected volstaat.',
        },
        usage: {
          title: 'Hoe doe je mee',
          content:
            'Bij je eerste bezoek aan IAMconnected maak je eenmalig een persoonlijke identiteit aan. Deze bestaat uit een zelf gekozen, unieke gebruikersnaam plus een sterk wachtwoord. Ter bevestiging ontvang je een e-mail.',
        },
        safety: {
          title: 'Veiligheid voorop',
          content:
            'Voor de veiligheid is het belangrijk dat elke persoon zijn eigen individuele account aanmaakt. Gebruik daarom geen groepsaccount, waarbij meerdere personen dezelfde inloggegevens gebruiken. Gebruik bij voorkeur een emailadres met de domeinnaam van je organisatie. Daarmee is duidelijk dat je namens je bedrijf inlogt.',
        },
        start: {
          title: '',
          content:
            'Dit is je startpagina van IAMconnected. Hier vind je de digitale dienst(en) waartoe jij toegang hebt. Eén klik op de gewenste tegel en je kunt direct aan de slag. Gebruik je meerdere diensten? Dan kun je via deze pagina steeds makkelijk wisselen.',
        },
        initial: {
          title: 'De eerste keer op deze startpagina',
          content:
            'Zie je de gewenste digitale dienst(en) nog niet op je startscherm? Dit los je op via Organisatiebeheer. Daar koppel je je persoonlijke IAMconnected account aan de organisatie waarvoor je werkt. De hoofdbeheerder kent je vervolgens de vereiste rechten toe. Je startpagina toont hierna vanzelf de voor jou toegankelijke dienst(en).',
        },
      },
      services: {
        title: 'Services',
        pendingService:
        {
          title: 'Je koppelverzoek moet goedgekeurd zijn voor toegang tot services',
          description: 'Je koppelverzoek is nog niet goedgekeurd. Neem contact op met een beheerder van je organisatie om goedgekeurd te worden en toegang te krijgen tot services via IAMconnected.'
        },
        noServicesAvailable:
          'Er zijn momenteel geen services actief voor de geselecteerde organisatie.',
      },
      sectionRequestService: {
        title: 'Services aanvragen',
        description: 'Portbase en andere partijen bieden applicaties aan voor verschillende doelgroepen in de logistieke keten. Zoek en abonneer op services om jullie logistieke proces verder te verbeteren!',
        serviceButton: 'Portbase services',
        otherButton: 'Andere services',
        noService: {
          title: 'Je organisatie heeft nog geen toegang tot services',
          description: 'Dit komt doordat de service aanvraag nog niet is afgerond of verwerkt door Portbase. Als er onlangs een service bij Portbase is aangevraagd, controleer dan eerst of de tekenbevoegde in de organisatie het contract heeft ondertekend en retour gezonden. Nadat Portbase het ondertekende contract heeft ontvangen gaan wij de aangevraagde service(s) gebruiksklaar maken. Dit duurt doorgaans één werkdag. Per e-mail houden we de aanvrager op de hoogte.'
        }
      },
      notLoggedIn: 'Je bent momenteel niet ingelogd.',
      notRegistered: 'Klaar om te beginnen?',
      privacy: 'Privacy Verklaring en Algemene Voorwaarden',
    },
    register: {
      title: 'Account aanmaken',
      subTitle: 'Maak hier jouw IAMconnected account aan namens jouw bedrijf.',
    },
    myProfile: {
      title: 'Profiel',
      verification: {
        title: 'Extra verificatie (MFA)',
        introText:
          'Extra verificatie verhoogt jouw accountbeveiliging bij het aanmelden bij IAMconnected en andere services die je gebruikt. Voor meer informatie kijk op de ',
        supportLink: 'Portbase support pagina.',
        secondIntroText:
          'Nieuwe smartphone? Meld je eerst met je oude smartphone aan, ga naar profile en kies daar voor een MFA reset. Hierna kan je opnieuw MFA configureren voor je nieuwe smartphone.',
        secondTabIntroText:
          'Gevallen waarin je jouw Google Authenticator-token mogelijk wil intrekken:',
        secondTabReason1:
          'Je telefoon is zoekgeraakt en je wil ervoor zorgen dat onbevoegde gebruikers geen toegang hebben tot jouw account',
        secondTabReason2:
          'Je wilt Google Authenticator op een andere telefoon installeren',
        secondTabOutroText:
          'Wil je jouw bestaande Google Authenticator-token intrekken en/of opnieuw configureren?',
        warningModalTitle: 'MFA inschakelen',
        warningModalContent:
          'Eenmaal ingeschakeld kan MFA niet meer worden uitgeschakeld Weet je zeker dat je door wilt gaan?<br /><br /> <strong>Let op:</strong> Als je door gaat wordt je automatisch uitgelogd zodat je bij opnieuw aanmelden MFA kan instellen op je mobiel of met een beveiligingssleutel.',
        warningModalResetMFAContent:
          'Als je door gaat wordt je automatisch uitgelogd zodat je bij opnieuw aanmelden MFA kan instellen op je mobiel of met een beveiligingssleutel.',
      },
      identification: {
        title: 'Identificatie',
        successMessage:
          'De status voor externe identificatie wordt weer bijgewerkt na succesvol doorlopen van het reguliere identificatieproces.',
        notIdentified:
          'Er is een uitnodiging verstuurd naar jouw email adres, met hierin de vraag om jezelf te identificeren. Als je deze niet ontvangen hebt klik dan op de onderstaande button om hem opnieuw te versturen.',
      },
      personalInformation: {
        title: 'Accountgegevens',
      },
      businessInformation: {
        title: 'Zakelijke gegevens',
        introText: `Deze gegevens zijn van toepassing op jouw huidig geselecteerde organisatie <strong>{{ name }}</strong>.`,
        changeRequest:
          'Je hebt een openstaand verzoek voor het wijzigen van je contact gegevens. <br />Deze dient door de hoofdbeheerder goedgekeurd te worden, indien dit niet gewenst is ',
      },
      businessRoles: {
        title: 'Organisatierollen',
        introText: `Je vervult binnen je huidige organisatie onderstaande rol(len).`,
        noRolesAvailable: `Er zijn momenteel geen rollen binnen deze organisatie aan je verbonden.`,
      },
      changePassword: {
        title: 'Wachtwoord wijzigen',
      },
      formLabels: {
        name: 'Naam',
        firstName: 'Voornaam',
        lastName: 'Achternaam',
        userName: 'Gebruikersnaam',
        primaryEmail: 'Zakelijk emailadres',
        mobilePhone: 'Zakelijk telefoonnummer',
        oldPassword: 'Oud wachtwoord',
        newPassword: 'Nieuw wachtwoord',
        confirmNewPassword: 'Bevestig nieuw wachtwoord',
        dateOfBirth: 'Geboortedatum',
        documentNumber: 'Documentnummer',
        validityOfDocument: 'Geldigheid document',
        identificationStatus: 'Identificatie status',
      },
    },
    changeBusinessInformationDialog: {
      step1: 'Let op!',
      step2: 'Wijziging aanvragen',
      requestButton: 'Wijziging aanvragen',
      warning:
        'Let op! Wijzigingen moeten eerst geaccepteerd worden door de hoofdbeheerder voordat ze zichtbaar worden.',
      success:
        'De aanvraag is in behandeling, de hoofdbeheerder van jouw organisatie dient nu jouw verzoek tot wijziging goed te keuren. Tot die tijd kan je tijdelijk niet gebruik maken van de diensten bestemd voor jouw organisatie.',
    },
    requiredFieldsDialog: {
      title: 'Het volgende ontbreekt nog voor {{ name }}',
    },
    newUserDialog: {
      chooseRole: {
        title: 'Welkom bij IAMconnected',
        introText:
          'Wij gaan je helpen om snel en eenvoudig je IAMconnected account in te stellen.',
        roleQuestion: 'Wat is je rol binnen jouw organisatie?',
        hint: 'Selecteer de rollen die onderdeel zijn van jouw functie',
      },
      chooseCoupleOrganization: {
        title: 'Organisatie zoeken'
      },
      chooseNewUser: {
        intro: 'Je account is nog niet gekoppeld aan een organisatie. <br /> Een gekoppelde organisatie is nodig om een service te kunnen aanvragen en gebruiken.',
        searchOrgButton: 'Zoek en koppel jouw organisatie',
        newOrganization: 'Nieuwe organisatie registreren',
        orgRegistration: {
          p1: 'Staat jouw organisatie er niet tussen? Laat dan je',
          p2: 'hoofdbeheerder',
          p3: 'en',
          p4: 'tekenbevoegde',
          p5: 'deze eerst registreren.'
        },
        adminTooltip: 'De aanmaker van deze organisatie zal automatisch worden geregistreerd als hoofdbeheerder. <br> <br>Een hoofdbeheerder is de persoon die verantwoordelijk is voor de configuratie en het dagelijkse beheer van deze organisatie.',
        reprTooltip: 'De registratie van uw organisatie moet voor gebruik eerst worden goedgekeurd en ondertekend door de tekenbevoegde. <br><br> Een tekenbevoegde is een natuurlijk persoon die volgens het uittreksel van de Kamer van Koophandel bevoegd is om namens uw onderneming te tekenen.',
        step1: {
          title: 'Organisatie',
          introText: 'Jouw account is nog niet gekoppeld aan een organisatie. Zoek en koppel jezelf aan een organisatie om verder te gaan.',
          pcsText:
            'Heb je als nieuwe organisatie een service bij Portbase aangevraagd? Je krijgt een e-mail van Portbase waarmee jij Portbase aan IAMconnected kan koppelen. Klik op de link in de mail om te migreren.',
        },
        step2: {
          title: 'Afronding',
          introText:
            'Je staat op het punt om jezelf te koppelen aan de organisatie',
          secondIntroText:
            'Let op, de hoofdbeheerder van deze organisatie moet wel eerst jouw verzoek goedkeuren.',
        },
        couple: 'Koppel mij aan deze organisatie',
        createOrganization: 'Staat uw organisatie er nog niet tussen?',
        registerHbr: 'Aanmelden voor Havenbedrijf Rotterdam?',
        registerHbrIntro:
          'Klik op de onderstaande button om jouw organisatie aan te maken.',
      },
      choosePcsOrNew: {
        title: 'Welkom bij IAMconnected',
        introText:
          'Do you want to migrate your Portbase PCS account to IAMconnected?',
        buttons: {
          userHasPcs: 'Ja, migreer Portbase PCS',
          userIsNew: 'Nee, ga verder voor ECT of HbR',
          newOrg: 'Nieuwe organisatie',
        },
      },
      userUsesPcs: {
        step1: {
          title: 'Login bij PCS',
          introText:
            'Open in een nieuwe tab PCS en log daar in met je gegevens',
          secondIntroText: `Aan de linkerkant bij <strong>My services</strong> vind je het kopje "Koppel je PCS-account aan IAMconnected". Als je op de link klikt word je doorgestuurd naar een speciale applicatie. Indien deze link er niet is, dan is je organisatie nog niet klaar voor IAMconnected. Neem hierover contact op met uw hoofdbeheerder.`,
          buttonLabel: 'Open PCS',
        },
        step2: {
          title: 'Login bij IAMconnected',
          introText: `Je wordt doorgestuurd naar de migratie app. In deze speciale applicatie kan je jouw PCS account aan je nieuwe IAMconnected account koppelen. Klik op "Login to IAMconnected`,
        },
        step3: {
          title: 'Link de accounts',
          introText: `Klik op "Link your PCS account with IAMconnected", hierna zullen de accounts succesvol gekoppeld zijn.`,
          secondIntroText: `Als je dit scherm ziet kun je de wizard sluiten en zal het scherm automatisch ververst worden. Je account is nu klaar voor gebruik.`,
        },
      },
    },
    checkedid: {
      intro: {
        title: 'CheckedID verificatie',
        introText: 'Als onderdeel van de registratie dient u zich als tekenbevoegde te identificeren. Dit is een essentieel onderdeel van het veiligheidsaspect binnen de Haven community. Uiteraard zal uw privacy worden gewaarborgd.',
        content: 'Het identificatieproces verloopt via drie stappen.<br/></br>Doorloop dit identificatieproces binnen 14 dagen. Zo lang is deze code geldig.',

      },
      step1: {
        title: 'Stap 1',
        introText: 'Installeer de CheckedID app via de app stores voor Android of iOS.',
        content: `Toegang tot uw camera is nodig voor foto's van uw ID-document en uzelf. U wordt gevraagd de NFC-functie aan te zetten, zodat de chip van uw ID-document uitgelezen kan worden.`,
      },
      step2: {
        title: 'Stap 2',
        introText: 'Gebruik de CheckedID app om onderstaande QR-code te scannen of geef de code in',
        content: 'Deze code is 14 dagen geldig. Mocht de code zijn verlopen, dan kan uw beoogd hoofdbeheerder de identificatie-email opnieuw versturen.',
      },
      step3: {
        title: 'Stap 3',
        introText: 'Doorloop het identificatie proces in de CheckedID app. Bij goedkeuring van de identificatie zal het organisatie registratie verzoek naar u worden gestuurd ter beoordeling.',
        content: 'Voor meer informatie of ondersteuning verwijzen we u door naar onze <a href="https://support.portbase.com/identificatie/">Support Pagina</a>.',
      }
    },
    organizationDetails: {
      warningMessageSection: {
        openRequestp1: 'Je hebt een open verzoek openstaan. Deze moet eerst afgerond zijn voordat je een ander verzoek kunt indienen. Ga naar',
        openRequestp2: 'om de status te bekijken.',
        openRequestAtDifferentOrganization: 'Je hebt nog een onafgemaakt verzoek. Ga naar {{ name }} in het <a href="organizations/{{orgId}}">Organisatiebeheer</a> scherm om deze in te dienen of te annuleren.'
      },
      title: 'Bedrijfsgegevens',
      companyInfoSection: {
        title: 'Nieuw opgehaalde bedrijfsgegevens moeten gevalideerd worden door de tekenbevoegde',
        button: 'Bedrijfsgegevens ophalen',
        authSignatoryValidated: 'Tekenbevoegde is gevalideerd',
        authSignatoryNotValidated: 'Tekenbevoegde is niet gevalideerd',
        userNotAllowed: 'U heeft niet de benodigde rechten om organisatiegegevens aan te passen. <br> Neem contact op met uw hoofdbeheerder <b>{{ name }}</b> als er gegevens moeten worden gewijzigd.'
      },
      externalChangeForm: {
        tekst: 'Overige bedrijfgegevens zoals o.a. contactpersonen en BTW nummer kunnen worden gewijzigd in dit ',
        link: 'wijzigingsformulier'
      },
      newChangeRequestp1:
        'Je wijzigingsverzoek is aangevraagd. Als de tekenbevoegde van jouw organisatie de wijziging heeft goedgekeurd ontvang je een activatie code van hem/haar.',
      reprNotValidated:
        'De tekenbevoegde is niet gevalideerd, ga naar contactgegevens en kies een tekenbevoegde.',
      formDisabledMessage:
        'Let op: de organisatiedetails kunnen alleen worden bewerkt door de hoofdbeheerder van de organisatie. De hoofdbeheerder dient ingelogd te zijn voor de betreffende organisatie.',
      organizationInformation: {
        title: 'Organisatie-informatie',
      },
      organizationType: {
        introText: 'Selecteer hier jouw organisatie type.',
      },
      businessInformation: {
        introText: 'Voer hier jouw zakelijke informatie in.',
      },
      contactInformation: {
        title: 'Contactgegevens',
        noData:
          'Er is geen tekenbevoegde gevonden in het KvK, vul deze daarom handmatig in. Upload aanvullend bewijs, om te bewijzen dat deze persoon tekenbevoegdheid heeft voor jouw organisatie. Bijvoorbeeld een uittreksel van het KvK.',
        other: 'Handmatig een tekenbevoegde invoeren'
      },
      identificationPreferences: {
        title: 'Externe identificatie inschakelen',
        toggleLabel: 'Externe identificatie inschakelen',
        introText:
          'Wil je als bedrijf absoluut zeker zijn dat je met de juiste persoon van het account te maken hebt? Schakel dan externe identificatie in.',
        warningText:
          'Let op: Is externe identificatie eenmaal ingeschakeld, dan is dit niet meer uit te schakelen!',
        warningModal: {
          title: 'Externe identificatie inschakelen',
          warningText:
            'Let op: Is externe identificatie eenmaal ingeschakeld, dan is dit niet meer uit te schakelen!',
          introText:
            'Wil je als bedrijf absoluut zeker zijn dat je met de juiste persoon van het account te maken hebt? Schakel dan externe identificatie in.',
          content: '',
        },
      },
      authenticationPreferences: {
        title: 'Extra beveiliging inschakelen?',
        introText:
          'Wil je als bedrijf absoluut zeker zijn dat alleen de juiste personen binnen je organisatie toegang hebben? Schakel dan Multi-factor Authenticatie (MFA) in. Bij het inloggen op IAMconnected wordt de gebruiker dan gevraagd om - naast het wachtwoord - een extra code in te toetsen. Voor meer informatie zie <a href="https://support.portbase.com/kennis/multi-factor-authentication/" target="_blank">Portbase support page.</a>',
        warningText:
          'Let op: Is MFA eenmaal ingeschakeld en wordt dit formulier vervolgens bijgewerkt, dan is dit niet meer uit te schakelen!',
        toggleLabel: 'MFA inschakelen',
        warningModal: {
          title: 'MFA inschakelen',
          warningText:
            'Let op: Is MFA eenmaal ingeschakeld en wordt dit formulier vervolgens bijgewerkt, dan is dit onomkeerbaar!',
          introText:
            'Door MFA aan te zetten, wordt dit aangezet voor alle medewerkers van jouw organisatie, het is aan jou om binnen jouw organisatie te communiceren dat voortaan MFA benodigd is om te kunnen inloggen.',
          content:
            'Jouw medewerkers zullen gevraagd worden MFA te configureren bij de eerstvolgende login.',
        },
      },
      attestationPreferences: {
        title: 'Periodieke validatie inschakelen?',
        introText:
          'Wil je als bedrijf dat je medewerker en hun gegevens periodiek gevalideerd worden? Schakel dan Periodieke Validatie (Attestatie) in. Periodieke validatie kan vereist worden door achterliggende applicaties die zeker willen zijn dat jouw werknemers en hun gegevens nog actueel zijn. Door de periodieke validatie kunnen jouw werknemers toegang behouden/ verkrijgen tot gevoelige diensten en informatie.',
        warningText:
          'Let op! Is Perdiodiek validatie eenmaal ingeschakeld dan is deze functionaliteit niet meer uit te schakelen.',
        toggleLabel: 'Periodieke validatie inschakelen',
        warningModal: {
          title: 'Periodieke validatie inschakelen',
          warningText:
            'Let op: Is Periodieke Validatie eenmaal ingeschakeld en wordt dit formulier vervolgens bijgewerkt, dan is dit onomkeerbaar!',
          introText:
            'Door Periodieke Validatie aan te zetten, wordt de hoofdbeheerder periodiek gevraagd de medewerkers en hun gegevens te valideren.',
          content:
            'Door de Periodieke Validatie kunnen jouw werknemers toegang behouden/ verkrijgen tot gevoelige diensten en informatie van achterliggende applicaties.',
        },
      },
      formLabels: {
        name: 'Organisatienaam',
        address: 'Adres',
        zipCode: 'Postcode',
        zip_code: 'Postcode',
        city: 'Plaats',
        country: 'Land',
        country_uncode: 'Land',
        cocId: 'KVK nummer',
        coc_id: 'KVK nummer',
        coc_number: 'KVK nummer',
        duns: 'DUNS nummer',
        eoriNumber: 'EORI nummer',
        eoriEntry: 'EORI entry sequence nummer',
        eoriExit: 'EORI exit sequence nummer',
        eanCode: 'EAN code',
        portAuthorityId: 'Port Authority ID',
        port_authority_id: 'Port Authority ID',
        organizationAdministrator: 'Hoofdbeheerder',
        telephone_organizationAdministrator: 'Telefoonnummer',
        email_organizationAdministrator: 'Email adres hoofdbeheerder',
        admin_name: 'Hoofdbeheerder',
        admin_phone: 'Telefoonnummer',
        admin_email: 'Email adres hoofdbeheerder',
        phone: 'Telefoonnummer tekenbevoegde',
        email: 'E-mailadres',
        authorizedSignatory: 'Naam tekenbevoegde',
        repr_name: 'Naam tekenbevoegde',
        establishmentNumber: 'Vestigingsnummer',
        est_id: 'Vestigingsnummer',
        est_number: 'Vestigingsnummer',
        mfaEnabled: 'MFA inschakelen',
        status: 'Status',
        type: 'Type',
        member_of: 'Gebruikersrechten',
        choose: 'Kies een organisatie',
        organizationType: 'Organisatie type',
        organization_types: 'Organisatie types',
        birth_place: 'Geboorteplaats',
        authorized_representative: 'Tekenbevoegde',
        establishment: 'Vestiging',
        telephone_authorized_representative: 'Telefoonnummer tekenbevoegde',
        repr_phone_number: 'Telefoonnummer tekenbevoegde',
        repr_phone: 'Telefoonnummer tekenbevoegde',
        email_authorized_representative: 'Email adres tekenbevoegde',
        repr_email_address: 'Email adres tekenbevoegde',
        repr_email: 'Email adres tekenbevoegde',
        rsin: 'RSIN nummer',
        telephoneNumber: 'Telefoonnummer',
        telephone: 'Telefoonnummer',
        date_of_birth: 'Geboortedatum tekenbevoegde (DD-MM-YYYY)',
        repr_date_of_birth: 'Geboortedatum tekenbevoegde (DD-MM-YYYY)',
        lastVerifiedDate: 'Laatste keer gevalideerd',
        region: 'State',
        province: 'Provincie'
      },
      sectionOrganizationAdministrator: {
        noOrganizationAdministrator: 'Geen hoofdbeheerder bekend',
        changeOrganizationAdministrator: 'Wijzig hoofdbeheerder',
        confirmChangeOrganizationAdministrator:
          'Na goedkeuring door de tekenbevoegde wordt de wijziging geactiveerd door de nieuwe hoofdbeheerder.',
        noUsers: 'Er zijn geen gebruikers voor deze organisatie',
      },
      sectionCompanyInfo: {
        title: 'Bedrijfsgegevens',
        loading: 'Even geduld alsjeblieft, we halen de data op',
        errorTexts: {
          changeRequest: {
            unknownResource: 'Het KvK-nummer is niet correct, de gegevens kunnen niet worden geüpdatet. <br>Neem contact op met Portbase Sales via +31 (0)88 625 25 34 om verder te gaan.',
          },
          organization: {
            invalidResourceSelected:
              'Bedrijfsgegevens niet gevonden, de gegevens kunnen niet worden geüpdatet. <br>Neem contact op met Portbase Sales via +31 (0)88 625 25 34 om verder te gaan.'
          },
        },
        showMore: 'Meer gegevens',
        showLess: 'Minder gegevens',
        unknown: 'Niet bekend',
        getOrgDataButton: 'Ophalen organisatie gegevens',
        internationalResults: 'Gevonden resultaten',
        cocNotFound:
          'De organisatiegegevens bij dit KvK nummer werden niet gevonden. Controleer de ingave van het KvK nummer. Mocht dit alsnog het probleem niet verhelpen neem contact op met Portbase Sales via +31 (0)88 625 25 34 om verder te gaan.',
        dunsNotFound:
          'De organisatiegegevens werden niet gevonden. Controleer de ingave van de organisatiegegevens. Mocht dit alsnog het probleem niet verhelpen neem contact op met Portbase Sales via +31 (0)88 625 25 34 om verder te gaan.',
        dunsDontKnow: 'Ik weet mijn DUNS nummer niet',
        dunsDialog: {
          title: 'DUNS nummer zoeken',
          description:
            'Vul hieronder alle velden in en druk op zoeken, mocht je bedrijf niet gevonden worden. Neem dan contact op met de service desk.',
        },
        mainAdminTitle: 'Let op:',
        mainAdminNotice: 'Met het aanmaken word je automatisch de hoofdbeheerder van deze organisatie.',
        fileUpload: {
          title: 'Upload bewijs van tekenbevoegdheid (jpg, pdf, png)',
          drop: 'Sleep hier je bestand(en)',
          choose: 'Bestand kiezen',
        }
      },
      sectionContactDetails: {
        title: 'Contact gegevens',
        authorizedrepresentative: 'Tekenbevoegde',
        validateEmail: 'Om door te gaan valideer eerst je emailadres',
        validateEmailButton: 'Emailadres valideren',
      },
      sectionExtraInformation: {
        title: 'Extra informatie',
      },
      sectionSecurity: {
        title: 'Beveiliging',
        attestationFrequency: 'Frequentie herinnering periodieke validatie',
        frequency: {
          oneMonth: '1 maand',
          threeMonths: '3 maanden',
          halfYear: 'Half jaar',
        },
      },
      sectionReferenceData: {
        title: 'Referentiedata',
      },
      sectionSaveInformation: 'Wijzigingsaanvraag nog niet ingediend'
    },
    organizationTypes: {
      AGENT: 'Agent',
      BARGE_OPERATOR: 'Binnenvaartoperator',
      EMPTY_DEPOT: 'Empty depot',
      FORWARDER: 'Expediteur',
      EXPORTER: 'Exporteur',
      IMPORTER: 'Importeur',
      INSPECTION_STATION: 'Keurpunt',
      SHIPBROKER: 'Rederij, cargadoor',
      SKIPPER: 'Schipper',
      RAIL_OPERATOR: 'Spooroperator, spoorvervoerder',
      TERMINAL: 'Terminal',
      ROAD_HAULIER: 'Wegvervoerder',
      CUSTOMS: 'Douane',
      FOOD_HEALTH_SERVICE: 'NVWA',
      PORT_AUTHORITY: 'Havenbedrijf',
      RAILWAY_AUTHORITY: 'Spoorbeheerder',
      IT_SERVICE_PROVIDER: 'IT Service provider',
    },
    organizationRoles: {
      ADMIN: 'Administrator',
      BACK_OFFICE: 'Back office',
      BARGE_OPERATOR: 'Barge operator',
      BARGE_TERMINAL_OPERATOR: 'Barge terminal operator',
      BARGE_VIEWER: 'Barge viewer',
      BOATMEN: 'Boatmen',
      CAPTAIN: 'Captain',
      CARGO_HANDLING_AGENT: 'Cargo handling agent',
      CARGO_TRACKER_ORG: 'Cargo tracker organization',
      CARRIER: 'Carrier',
      CONTAINER_LESSOR: 'Container lessor',
      CONTAINER_OPERATOR: 'Container operator',
      CUSTOMS: 'Customs',
      DEPOT_OPERATOR: 'Depot operator',
      DEVELOPER: 'Developer',
      FINANCIAL_DECLARANT: 'Financial declarant',
      FORMER_SHIP_OPERATOR: 'Former ship operator',
      FORWARDER: 'Forwarder',
      FUTURE_SHIP_OPERATOR: 'Future ship operator',
      HELP_DESK: 'Help desk',
      INSPECTION_LOCATION: 'Inspection location',
      INTEGRAL_PLANNER: 'Integral planner',
      MVL_DECLARANT: 'MVL declarant',
      ORG_ADMIN: 'Organization administrator',
      PILOT: 'Pilot',
      PORT_AUTHORITY: 'Port authority',
      RAIL_OPERATOR: 'Rail operator',
      RAIL_TERMINAL_OPERATOR: 'Rail terminal operator',
      RAIL_VIEWER: 'Rail viewer',
      RAILWAY_AUTHORITY: 'Railway authority',
      REGISTRANT: 'Registrant',
      ROAD_HAULIER: 'Road haulier',
      ROAD_TERMINAL_OPERATOR: 'Road terminal operator',
      ROAD_VIEWER: 'Road viewer',
      SHIP_OPERATOR: 'Ship operator',
      TERMINAL_OPERATOR: 'Terminal operator',
      TOWAGE: 'Towage',
      TRACTION_SUPPLIER: 'Traction supplier',
      VIP_INSPECTION_REQUESTER: 'VIP inspection requestor',
      VIP_MVL_ADMIN: 'VIP/MVL administrator',
      VWA: 'VWA',
      WASTE_COLLECTOR: 'Waste collector',
    },
    organizationsManagement: {
      title: 'Organisatiebeheer',
      tabs: {
        coupleWithOrganization: 'Koppel met extra organisatie',
        myOrganizations: 'Mijn organisaties',
        addOrganization: 'Nieuwe organisatie aanmelden',
        finishRequest: 'Aanmelding afronden',
      },
      tooltip: {
        delete: 'Ontkoppel de gebruiker van de organisatie',
      },
      overview: {
        noDataToDisplay:
          'Je bent momenteel niet aan een organisatie gekoppeld. Gebruik onderstaande knop om je te koppelen met een organisatie.',
      },
      dialog: {
        title: 'Ontkoppelen',
        content:
          'Je account wordt ontkoppeld van {{ orgName }}. Je account blijft actief voor de overige organisaties.',
        content_main: 'Je account wordt verwijderd',
        content_main_multiple_orgs:
          'Je account wordt verwijderd voor {{ orgName }} en de gekoppelde extra organisaties.',
      },
    },
    organizationsCoupling: {
      title: 'Koppelverzoek voor extra organisatie',
      content:
        'Koppelverzoek voor extra organisatie. Als gebruiker kun je ook werkzaamheden uitvoeren voor een andere organisatie dan je eigen organisatie. Hiervoor dien je een koppelverzoek in voor een extra organisatie. De beheerder van de extra organisatie moet deze koppeling goedkeuren.',
      formLabels: {
        organizationName: 'Organisatienaam',
        organizationRoles: 'Organisatie rol(len)',
      },
      attestation: {
        status: 'Gevalideerd',
        valid: 'Je account is gevalideerd door de beheerder',
        invalid:
          'De hoofdbeheerder van deze organisatie dient je account te valideren',
        validated_date: 'Laatste keer gevalideerd',
      },
    },
    openRequests: {
      title: 'Open verzoek',
      organization: 'Organisatie',
      activationCode: 'activatie code',
      noOpenRequests: 'Er zijn momenteel geen open verzoeken',
      verifyEmail: 'Emailadres valideren',
      changeUserProfile: 'Wijzing profiel',
      description: {
        verifyEmail: 'Er staat een verzoek open om je emailadres te valideren.',
        changeUserProfile: 'Er staat een wijzigingsverzoek open voor je profiel.'
      },
      requestWithStatusNew: {
        p1: 'Je hebt nog een onafgemaakt verzoek. Ga naar',
        p2: 'om deze in te dienen of te annuleren.'
      },
      cancelRequest:
        'Indien registratie aanvraag niet langer gewenst is klik hier.',
      activate: 'activeren',
      newOrganization: 'nieuwe organisatie',
      changeOrganizationDetails: 'verander organisatie details',
      changeOrganizationReferenceData: 'verander organisatie referentie data',
      changeOrganizationRepresentative: 'verander tekenbevoegde',
      changeOrganizationAdmin: 'verander hoofdbeheerder',
      changeOrganizationType: 'verander organisatie type',
      validateOrganizationDetails: 'valideer organisatie details',
      refreshOrganizationDetails: 'ververs organisatie details',
      newService: 'Nieuwe service',
      editService: 'Service wijzigen',
      changeBusinessInformation: 'Verander zakelijke informatie',
      updateOrganizationDetails: 'Verander organisatie gegevens',
      sectionProcessInsights: {
        createOrg: {
          title: 'Status organisatie registratieverzoek',
          step1: {
            title:'Identificatie',
            content: 'Voor het registreren van de organisatie zal de identiteit van de tekenbevoegde moeten worden vastgesteld. Uw tekenbevoegde heeft een email inclusief instructies hiervoor ontvangen.',
            button: 'Opnieuw identificatie email versturen'
          },
          step2: {
            title: 'Beoordeling',
            content: 'Portbase zal uw organisatieverzoek beoordelen.'
          },
          step3: {
            title: 'Akkoord geven',
            content: 'Er is per email een uitnodiging voor het tekenen van het contract naar de tekenbevoegde  verstuurd.',
            button: 'Opnieuw uitnodiging versturen'
          },
        },
        changeOrgDetails: {
          title: 'Status wijzigingsverzoek organisatiegegevens',
          step1: {
            title: 'Identificatie',
            content: 'Voor deze wijziging zal de identiteit van de tekenbevoegde (opnieuw) moeten worden <u>gevalideerd</u>. Uw tekenbevoegde heeft instructies per email ontvangen.'
          },
          step2: {
            title: 'Beoordeling',
            content: 'Portbase zal uw wijzigingsverzoek beoordelen.'
          },
          step3: {
            title: 'Akkoord geven',
            content: 'Er is per email een uitnodiging voor het tekenen van het contract naar de tekenbevoegde  verstuurd.'
          },
          footer: 'Voor meer informatie zie onze <a target="_blank" href="https://support.portbase.com/iamconnected/">Support Pagina</a>;',
          sectionRequestDetails: {
            current: 'Huidig',
            new: 'Nieuw'
          }
        }
      }
    },
    userManagement: {
      title: 'Gebruikersbeheer',
      filter: 'Filter gebruikers',
      warningMessages: {
        emailNotVerified:
          'Deze gebruiker heeft zijn/haar emailadres nog niet geverifieerd. De gebruiker kan zijn emailadres verifiëren middels de link in de email die wij hebben verstuurd. <br />De gebruiker dient binnen 24 uur op de verificatie link in de email te klikken anders wordt dit wijzigingsverzoek geannuleerd.',
        deleteUserAccount:
          'Weet je zeker dat je deze gebruiker wilt verwijderen? Het gebruikersaccount zal volledig worden verwijderd.<br />Hierdoor kan het account ook niet meer gebruikt worden voor andere organisaties.',
        decoupleUserAccount:
          'Weet je zeker dat je deze gebruiker wilt verwijderen van jouw organisatie? De gebruiker zal ontkoppeld worden van jouw organisatie.',
      },
      periodicValidation: {
        selectEmployees: 'Selecteer medewerkers in de lijst',
        quarterLabel1: 'januari - maart',
        quarterLabel2: 'april - juni',
        quarterLabel3: 'juli - september',
        quarterLabel4: 'oktober - december',
        quarter: ' Kwartaal ',
        history: {
          title: 'Eerdere controles',
          numberOfEmployees: '{{amount}} van de {{total}} medewerkers ',
          numberOfEmployeesCompleted: '{{total}} medewerkers ',
          new: 'Niet voltooid',
          not_completed: 'Niet voltooid',
          completed: 'Voltooid',
          review: 'Controle',
          status: 'Status',
          number: 'Aantal',
          validatedBy: 'Validatie door',
        },
        completed: 'afgerond',
        lastLogin: 'nl{{date}}',
        notificationBar: {
          title: 'De kwartaalcontrole staat open',
          description: 'Er zijn {{count}} medewerkers om te valideren.',
          titleUsermanager: 'De kwartaalcontrole is bijna afgerond',
          descriptionUsermanager: 'Vraag je hoofdbeheerder om de gebruikersbeheerder(s) te valideren.',
          button: 'Start controle',
          descriptionOpen: 'Valideer al je medewerkers om af te ronden.',
          titleOpen: 'Je hebt een lopende kwartaalcontrole',
          buttonOpen: 'Hervat controle',
        },
        feedback: {
          success: {
            title: '{{count}} medewerkers gevalideerd',
            singleTitle: '1 medewerker gevalideerd',
          },
          alreadyCompleted: {
            title: 'De kwartaalcontrole is al afgerond',
            message: 'Een andere beheerder was je voor.',
          },
          userAdminRestriction: {
            title: 'Alleen de hoofdbeheerder kan gebruikersbeheerders valideren',
            message: 'Als gebruikersbeheerder is het niet mogelijk andere gebruikersbeheerders te valideren. Vraag je hoofdbeheerder voor hulp om de kwartaalcontrole af te ronden.',
          },
          featureUnavailable: {
            title: 'Deze functionaliteit is momenteel niet beschikbaar',
            message: 'Probeer het later nog eens.',
          },
        },
        dialog: {
          header: 'Alle medewerkers valideren',
          content: 'Je staat op het punt om alle medewerkers in één keer te valideren. Doe dit alleen als je zeker weet dat iedereen toegang mag houden.',
          submit: 'Alle medewerkers valideren'
        }
      },
      actions: {
        approve: 'Koppel de gebruiker aan de organisatie',
        reject: 'Weiger de gebruiker aan de organisatie te koppelen',
        suspend: 'Schort de rechten van de gebruiker op voor de organisatie',
        attestation: 'Valideer de gegevens van deze gebruiker',
        unsuspend: 'Herstel de rechten van de gebruiker voor de organisatie',
        delete: 'Verwijder de gebruiker van deze organisatie',
        usermanagers:
          'Geef de gebruiker de rechten op gebruikersrechten beheer',
        removeUsermanagers:
          'Ontneem de gebruiker de rechten op gebruikersrechten beheer',
        resetMFA: 'Reset Multi Factor Authentication',
        searchEmployee: 'Zoek medewerker',
        accessLevel: 'Toegangsniveau',
        organizationAccess: 'Organisatie toegang',

      },
      buttons: {
        makeUserManager: 'Maak gebruikersbeheerder',
        suspend: 'Schort gebruiker op',
        attestation: 'Valideer gebruiker',
        delete: 'Verwijder gebruiker',
        unsuspend: 'Herstel gebruiker',
        removeUserManager: 'Verwijder gebruikersbeheer',
        downloadMemberOverview: 'Download gebruikersoverzicht',
        resetMFA: 'Reset MFA',
        validateEmployee: 'Valideer medewerker',
        validateEmployeeSingle: 'Valideer 1 medewerker',
        validateEmployeePlural: 'Valideer {{count}} medewerkers',
        recover: 'Herstellen'
      },
      tabs: {
        quarterlyReview: 'Kwartaalcontroles'
      },
      tables: {
        firstName: 'Voornaam',
        lastName: 'Achternaam',
        login: 'Gebruikersnaam',
        last_login: 'Laatst ingelogd',
        date_submitted: 'Datum ingediend',
        date_approved: 'Datum goedgekeurd',
        actions: 'Acties',
        personalIdentification: 'Persoonlijke identificatie',
        businessEmail: 'Zakelijk email adres',
        businessPhone: 'Zakelijk telefoonnummer',
        roles: 'Rollen',
        successFullyIdentified: 'De gebruiker is succesvol geidentificeerd',
        notSuccessFullyIdentified: 'De gebruiker is succesvol geidentificeerd',
        emailValidated: 'Het zakelijk email adres is gevalideerd',
        emailNotValidated: 'Het zakelijk email adres is niet gevalideerd',
        userNotIdentified:
          'Let op! deze gebruiker kan nog niet geaccepteerd worden omdat hij zichzelf nog niet heeft geïdentificeerd.',
        expiry: 'Einddatum (optioneel)',
        personalInformation: 'Persoonlijke gegevens'
      },
    },
    serviceManagement: {
      title: 'Servicebeheer',
      data: 'Gegevens',
      example: 'Voorbeeld',
      logo: 'Logo',
      serviceOverview: {
        title: 'Door mijn organisatie aangeboden services',
        newService: 'Nieuwe service aanbieden',
      },
      imageUpload: {
        title:
          '<strong>Logo uploaden</strong><br />Maximaal 80x80 pixels en jpg of png formaat.',
        delete: 'Logo verwijderen',
        maxImageSize: 'Maximale groote afbeelding 1 MB',
        fileType: 'Alleen afbeeldingen zijn toegestaan ( JPG | PNG )',
        maxImageResolution: 'Maximale afbeeldingsgrootte 80*80px',
      },
      formLabels: {
        name: 'Naam',
        title: 'Titel',
        logo: 'Logo',
        redirect_uri: 'Redirect uri',
        description: 'Beschrijving',
        link_url: 'Link URL',
        window_target: 'Window target',
        client_id: 'Client ID',
      },
      success:
        'Gelukt! Er staat een aanvraag open bij Portbase voor deze nieuwe service',
      openRequest:
        'Je hebt al een openverzoek, deze dient eerst afgerond te worden voordat je deze service kan wijzigen.',
      tabData: 'Gegevens',
      tabAccessControl: {
        title: 'Toegangsbeheer',
        accessToMyService: 'Toegang tot mijn service',
        accessToMyServiceSubtitle:
          'De lijst met onderstaande organisaties hebben toegang tot jouw service.',
        buttonRevokeAccess: 'Toegang ontnemen',
        giveAccessToMyService: 'Organisatie toegang geven tot mijn service',
        giveAccessToMyServiceSubtitle:
          'Zoek een organisatie op, om hem toegang te geven tot jouw service.',
        searchCompanyInputFieldHint: 'Zoek een organisatie',
        searchResults: 'Zoek resultaten:',
        searchNoResults: 'Geen organisatie gevonden',
        buttonGiveAccess: 'Toegang geven',
      },
    },
    changeOrganizationAdministrator: {
      title: 'Hoofdbeheerder wijzigen',
      introText: {
        firstParagraph:
          'Om de hoofdbeheerder te wijzigen moet je eerst een andere hoofdbeheerder selecteren en versturen.',
        secondParagraph: 'Let op: daarnaast dien je ook het',
        changeFormLinkUrl: 'https://www.portbase.com/form/wijzigen/',
        changeFormLinkText: 'Portbase wijzigingsformulier in te vullen.',
        thirdParagraph:
          'De wijziging zal pas na goedkeuring van onze salesafdeling worden doorgevoerd.',
      },
      form: {
        title: 'Wijzig hoofdbeheerder',
        introText:
          'Selecteer een nieuwe hoofdbeheerder uit de onderstaande lijst.',
        autoCompletePlaceholder: 'Zoek een medewerker',
        submitButton: 'Aanvraag versturen',
      },
      confirmNewOrganizationAdministrator: {
        firstParagraph:
          'Je staat op het punt de hoofdbeheerder te wijzigen van organisatie',
        secondParagraph:
          'Weet je zeker dat je de hoofdbeheerder wilt wijzigen?',
      },
    },
    newOrganizationAdministrator: {
      title: 'Nieuwe hoofdbeheerder',
      tooltip: 'In afwachting van goedkeuring door Portbase',
    },
    news: {
      title: 'Nieuws',
      subTitle: 'Lees hier alles over recente ontwikkelingen',
      items: [
        {
          title: 'Nieuwe functionaliteit: deze nieuwsfeed',
          details:
            'Aankondigingen en recente ontwikkelingen zullen hier getoond worden',
        },
      ],
    },
    feedback: {
      button: 'Contact customer service',
      title: 'Customer service',
      description1:
        'Als klant van Portbase kunt u 24/7 contact opnemen met onze Customer Service. Onze medewerkers kunnen u helpen in het Nederlands, Engels en Duits',
      description2: 'De Customer Service van Portbase is uw centrale aanspreekpunt voor al uw vragen over de services. U kunt terecht bij ons team van getrainde en gespecialiseerde medewerkers voor de volledige afhandeling van vragen en meldingen. Om een efficiënte afhandeling te kunnen garanderen werken kunt u voor iedere vraag direct bij ons terecht en proberen wij uw hulpvraag direct af te handelen.',
      allowContact: 'IAMconnected mag contact met mij opnemen',
      success: 'Gelukt, jouw hulpvraag zal in behandeling worden genomen door customer service!',
    },
    changeBusinessInformationRequestTypes: {
      change_business_information: 'Wijziging zakelijke informatie',
      change_user_profile: 'Wijziging gebruikers profiel',
    },
    verifyEmailPage: {
      title: 'Email verificatie',
      success: 'Bedankt, je emailadres is geverifieerd.',
      error: 'Er is iets fout gegaan met het verifieren van het emailadres.',
      errorEmailAlreadyVerified:
        'Het emailadres is al geverifierd, je hoeft verder geen actie te ondernemen.',
      errorLoggedin:
        'De verificatie link is verlopen, vraag opnieuw een verificatie email aan.',
      errorNotLoggedin:
        'De verificatie link is verlopen, login en vraag opnieuw een verificatie email aan.',
      errorLoggedinButton: 'Verificatie email opnieuw sturen',
    },
    migratingBusinessInformation: {
      sectionChangeEmail: {
        title: 'Wijzig gegevens',
        description:
          'Vul hieronder jouw nieuwe gegevens in, er zal een wijzigingsverzoek verstuurd worden naar jouw hoofdbeheerder. Na goedkeuring van jouw hoofdbeheerder zijn de wijzigingen actief.',
        emailField: 'Jouw zakelijke emailadres',
        phoneField: 'Jouw zakelijke telefoonnummer',
      },
      sectionEmailSend: {
        title: 'Er is een verificatie email verstuurd',
        description:
          'Je hebt een verificatielink ontvangen op {{businessEmail}}. Klik op de link in de email om je emailadres te verifieren. Mocht je deze mail niet hebben ontvangen, controleer dan je spamfolder.',
        button: 'Stuur verificatielink opnieuw',
        cancel: 'Emailadres verkeerd? Klik hier'
      },
      sectionVerifyEmail: {
        title: 'Valideer je emailadres',
        description:
          'Om de veiligheid van IAMconnected te vergroten moeten alle accounts één uniek emailadres hebben. Dit is het zakelijke emailadres geassocieerd met jouw organisatie.',
        question: 'Welk emailadres moet gekoppeld zijn aan jouw account?',
        button: 'Valideer',
        attention: 'Groeps-emailadressen worden sterk afgeraden. Dit emailadres wordt gebruikt voor wijzigingen in je account.'
      },
    },
    pageAcceptTermsAndConditions: {
      registration: {
        title: 'Registratie verzoek voor',
        subTitle: 'We hebben een organisatie registratie verzoek ontvangen van ',
        subTitle2: 'Dit registratie verzoek zal pas worden verwerkt na goedkeuring en tekenen door u, als geregistreerd tekenbevoegde van deze organisatie.'

      },
      change: {
        title: 'Wijzigingsverzoek voor',
        subTitle: 'We hebben een wijzigingsverzoek ontvangen van ',
        subTitle2: 'Deze aanpassing(en) zullen pas worden verwerkt na goedkeuring en tekenen door u, als geregistreerd tekenbevoegde van deze organisatie.'
      },
      error: {
        notFound: 'Deze link is niet meer geldig. Mogelijk is het verzoek verwijderd of al geaccepteerd.',
      },
      supportPage: 'Voor meer informatie bekijk onze <a target="_blank" href="https://support.portbase.com/iamconnected/">Support pagina\'s</a>',
      success: 'Je verzoek is succesvol afgerond, je krijgt een email ter bevestiging.',
      table: {
        header: {
          col1: 'Oude gegevens',
          col2: 'Nieuwe gegevens',
        }
      },
      termsAndConditions: {
        authorizedRepr: 'Ik ben de <a href="https://support.portbase.com/iamconnected/#wat-is-mijn-rol-binnen-iamconnected" class="text-blue-A100" target="_blank">tekenbevoegde</a> van organisatie ',
        GeneralTermsAndConditions: 'Ik ga akkoord met de <a href="https://www.portbase.com/algemene-voorwaarden/" target="_blank" class="text-blue-A100">algemene voorwaarden</a>',
        PatricipantConditions: 'Ik ga akkoord met de <a href="https://www.portbase.com/wp-content/uploads/2023/05/1205-Deelnemersvoorwaarden-IAMconnected-Portbase-B.V.-3.pdf" target="_blank" class="text-blue-A100">deelnemersvoorwaarden</a>',
        mainAdministrator: ' is <a href="https://support.portbase.com/iamconnected/#hoofd-beheerder" target="_blank" class="text-blue-A100">hoofdbeheerder</a> van mijn organisatie',
        acceptThisChange: 'Ik ga akkoord met dit verzoek',
        acceptAll: 'Je moet alle voorwaarden accepteren',
        okeAndSend: 'Akkoord en versturen'
      }
    }
  };
}
